import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { useSWRConfig } from 'swr'
import styled from 'styled-components'
import { MdDelete } from 'react-icons/md'
import { RiEdit2Fill } from 'react-icons/ri'
import { authContext } from '../../../../../context/auth-context'
import { deleteResourceById } from '../../../../../helpers/deleteResourceById'
import { SALES } from '../../../../../constant/routes'
interface Props {
  title: string
  image: string
  discount: number
  id: number
}

const DashboardSalesItem = ({ image, discount, title, id }: Props) => {
  const { token, destroyToken } = useContext(authContext)
  const { mutate } = useSWRConfig()
  const urlDelete = `offers/offer/${id}/`

  return (
    <StyledImageWrapper>
      <StyledImage src={image} />
      <StyledActionsWrapper>
        <Link to={`${SALES.edit}/${id}`}>
          <RiEdit2Fill className='edit-icon' />
        </Link>
        <MdDelete
          onClick={() => deleteResourceById({ url: urlDelete, token, mutate, destroyToken })}
          className='delete-icon'
        />
      </StyledActionsWrapper>
      <StyledInfoWrapper>
        <StyledPercentage>{discount}% off</StyledPercentage>
        <StyledTitle>{title}</StyledTitle>
      </StyledInfoWrapper>
    </StyledImageWrapper>
  )
}

export default DashboardSalesItem

const StyledImageWrapper = styled.div`
  max-width: 500px;
  min-width: 300px;
  min-height: 180px;
  max-height: 280px;
  width: 100%;
  height: 100%;
  position: relative;
`

const StyledImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 5px;
`

const StyledActionsWrapper = styled.div`
  position: absolute;
  top: 13px;
  right: 13px;
  display: flex;
  gap: 10px;

  .edit-icon {
    color: var(--primary-color);
    transform: scale(1.2);
  }
  .delete-icon {
    color: var(--delete);
    cursor: pointer;
    transform: scale(1.2);
  }
`

const StyledInfoWrapper = styled.div`
  position: absolute;
  left: 13px;
  bottom: 13px;
  right: 13px;
  color: var(--white);
`

const StyledPercentage = styled.p`
  font-family: 'Lora', serif;
  font-weight: var(--bold);
  font-size: 0.875rem;

  @media (min-width: 1350px) {
    font-size: 1rem;
  }
`

const StyledTitle = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1rem;
  font-weight: var(--black);

  &:hover {
    text-overflow: clip;
    width: auto;
    white-space: normal;
  }

  @media (min-width: 1350px) {
    font-size: 1.125rem;
  }
`
