import { useMemo } from "react";
import { RiEdit2Fill } from "react-icons/ri";
import { Link } from "react-router-dom";
import { IoMdAddCircleOutline, IoMdRemoveCircleOutline } from "react-icons/io";
import { PRODUCTS } from "../../constant/routes";
import { ProductTypes } from "../../interfaces/products.interface";
import {
  StyledActionsWrapper,
  StyledImage,
  StyledLink,
  StyledTableContainer,
} from "../UI/StyledTable";
import TableItems from "./TableItems/TableItems";
import { useOffersCtx } from "../../context/offers-context";
import moment from "moment";

interface Props {
  products: ProductTypes[];
  productsError?: Error;
  isModalTable?: boolean;
  pageSize?: number;
}

interface OriginalIdType {
  cell: { row: { original: ProductTypes } };
}

const ProductsTable = ({
  products = [],
  productsError,
  pageSize = 5,
}: Props) => {
  const { selectedProducts, showProductsModal, onSelectProductHandler } =
    useOffersCtx();
  const columns = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "productid",
      },
      {
        Header: "Κωδικός",
        accessor: "code",
      },
      {
        Header: "Όνομα",
        accessor: "name",
        Cell: ({ value }: { value: string }) => <p>{value}</p>,
      },
      {
        Header: "Εικόνα",
        accessor: "product_photo",
        Cell: ({ value }: { value: string }) => {
          return !value ? (
            <p>Δεν βρέθηκε εικόνα</p>
          ) : (
            <StyledLink href={value} target="_blank" image={value}>
              <StyledImage src={value} alt="" />
            </StyledLink>
          );
        },
      },
      {
        Header: "Τελευταία Επεξεργασία",
        accessor: "date_edited",
        Cell: ({ value }: { value: string }) => {
          return !value ? (
            <p>-</p>
          ) : (
            <p>{moment(value).format("DD/MM/YYYY")}</p>
          );
        },
      },
      // {
      //   Header: 'Μέγεθος Εικόνας',
      //   accessor: 'file_size',
      //   Cell: ({ value }: { value: string }) => {
      //     return !value ? <p>-</p> : <p>{value}</p>
      //   },
      // },
      {
        Header: "Ενέργειες",
        Cell: ({
          cell: {
            row: { original },
          },
        }: OriginalIdType) => (
          <>
            {!showProductsModal && (
              <StyledActionsWrapper>
                <Link to={`${PRODUCTS.edit}/${original.productid}`}>
                  <RiEdit2Fill className="edit-icon" />
                </Link>
              </StyledActionsWrapper>
            )}
            {showProductsModal && (
              <StyledActionsWrapper>
                {selectedProducts.every(
                  (product) => product.productid !== original.productid,
                ) && (
                  <IoMdAddCircleOutline
                    className="svgAdd"
                    size={28}
                    onClick={() => onSelectProductHandler(original)}
                  />
                )}
                {selectedProducts.some(
                  (product) => product.productid === original.productid,
                ) && (
                  <IoMdRemoveCircleOutline
                    className="svgRemove"
                    size={28}
                    onClick={() => onSelectProductHandler(original)}
                  />
                )}
              </StyledActionsWrapper>
            )}
          </>
        ),
      },
    ],
    [showProductsModal, onSelectProductHandler, selectedProducts],
  );

  return (
    <StyledTableContainer>
      <TableItems
        data={products}
        columns={columns}
        errorMessage={productsError}
        pageSize={pageSize}
      />
    </StyledTableContainer>
  );
};

export default ProductsTable;
