import { useMemo } from 'react'
import { AiFillCheckCircle } from 'react-icons/ai'
import { RiEdit2Fill } from 'react-icons/ri'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { ERPUSERS } from '../../constant/routes'
import { ErpUserTypes } from '../../interfaces/erpUser.interface'
import { StyledActionsWrapper, StyledTableContainer } from '../UI/StyledTable'
import TableItems from './TableItems/TableItems'

interface OriginalIdType {
  cell: {
    row: {
      original: {
        erp_id: number
        isRegistered: boolean
        department: string
      }
    }
  }
}

interface Props {
  erpUsers: ErpUserTypes[]
  erpUsersError: Error
}

const ErpUsersTable = ({ erpUsers = [], erpUsersError }: Props) => {
  const history = useHistory()

  const columns = useMemo(
    () => [
      {
        Header: 'ERP ID',
        accessor: 'erp_id',
      },
      {
        Header: 'ΑΦΜ',
        accessor: 'afm',
      },
      {
        Header: 'Πελάτης',
        accessor: 'department',
        Cell: ({
          cell: {
            row: { original },
          },
        }: OriginalIdType) => (
          <StyledP>
            {original.department}
            {original.isRegistered && (
              <StyledP
                as='span'
                title='Εγγεγραμμένος χρήστης
              '
              >
                <AiFillCheckCircle color='#04911c' size={16} />
              </StyledP>
            )}
          </StyledP>
        ),
      },
      {
        Header: 'Εγγραφή',
        Cell: ({
          cell: {
            row: { original },
          },
        }: OriginalIdType) => {
          const urlEditLink = `${ERPUSERS.edit}/${original.erp_id}`
          return (
            <StyledActionsWrapper>
              <button
                style={{ all: 'unset' }}
                disabled={original.isRegistered}
                onClick={() => history.push(urlEditLink)}
              >
                <RiEdit2Fill
                  size={19}
                  color={original.isRegistered ? '#BDBDBD' : '#000'}
                  cursor={original.isRegistered ? 'not-allowed' : 'pointer'}
                />
              </button>
            </StyledActionsWrapper>
          )
        },
      },
    ],
    [history]
  )

  return (
    <StyledTableContainer>
      <TableItems data={erpUsers} columns={columns} errorMessage={erpUsersError} />
    </StyledTableContainer>
  )
}

export default ErpUsersTable

const StyledP = styled.p`
  display: flex;
  align-items: center;
  gap: 4px;
`
