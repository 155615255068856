import { Route } from 'react-router-dom'
import { BFLOWUSERS } from '../../constant/routes'
import { BflowUserTypes } from '../../interfaces/bflowUser.interface'
import BflowUsers from '../../pages/BflowUsers/BflowUsers'

interface Props {
  bflowUsers: BflowUserTypes[]
  isLoading: boolean
  bflowUsersError: Error
}

const BflowUsersRoutes = ({ bflowUsers = [], isLoading: isBflowUsersLoading, bflowUsersError }: Props) => {
  return (
    <>
      <Route path={BFLOWUSERS.main} exact>
        <BflowUsers
          bflowUsers={bflowUsers}
          bflowUsersError={bflowUsersError}
          isLoading={isBflowUsersLoading}
        />
      </Route>
    </>
  )
}

export default BflowUsersRoutes
