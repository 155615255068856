import { Page, StyleSheet, Text, View, Image } from "@react-pdf/renderer";
import logo from "../../../../assets/login-logo.png";
import bgImg from "../../../../assets/last-pdf-bg.png";
import qrCode from "../../../../assets/qr-code.png";
import googlePlayLogo from "../../../../assets/google-play-logo.png";
import appStoreLogo from "../../../../assets/app-store-logo.png";
import PdfContactInfo from "./PdfContactInfo";
const styles = StyleSheet.create({
  page: { fontFamily: "Isotext Pro" },
  section: {
    flexGrow: 1,
    alignItems: "center",
    marginTop: 200,
  },
  footer: {
    marginTop: 70,
    padding: "0 35px 13px",
  },
  text: {
    color: "#00AFEF",
    width: 341,
    fontSize: 20,
    textAlign: "center",
    margin: "0 auto",
  },
  storeInfoText: {
    color: "#4A4A4A",
    fontSize: 18,
    width: 190,
  },
  storeInfoWrapper: {
    borderRadius: 20,
    padding: 20,
    backgroundColor: "#AACE46",
    margin: "35px 0",
    minWidth: "100%",
    flexDirection: "row",
    alignItems: "center",
  },
  qrCode: {
    width: 79,
    height: 79,
    margin: "0 24px",
  },
  logo: {
    width: 275,
    textAlign: "center",
  },
  storeImg: {
    width: 113,
    height: 33,
  },
  backgroundImage: {
    position: "absolute",
    width: "100%",
    height: "325px",
    bottom: 0,
    left: 0,
    right: 0,
  },
});

const LastPdfPage = () => {
  return (
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Image src={logo} style={styles.logo} />
        <Image src={bgImg} style={styles.backgroundImage} />
        <View style={styles.footer}>
          <Text style={styles.text}>
            Οι προσφορές ισχύουν μόνο για παραγγελίες μέσω της εφαρμογής B2B
          </Text>
          <View style={styles.storeInfoWrapper}>
            <View>
              <Image
                src={appStoreLogo}
                style={{ ...styles.storeImg, marginBottom: 11 }}
              />
              <Image src={googlePlayLogo} style={styles.storeImg} />
            </View>
            <Image src={qrCode} style={styles.qrCode} />
            <Text style={styles.storeInfoText}>
              Κάναμε τις παραγγελίες μία ευχάριστη, γρήγορη και απλή διαδικασία!
            </Text>
          </View>
          <PdfContactInfo />
        </View>
      </View>
    </Page>
  );
};

export default LastPdfPage;
