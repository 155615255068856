import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import DashboardNewsItem from './DashboardNewsItem/DashboardNewsItem'
import Spinner from '../../../../components/UI/Spinners/Spinner'
import { StyledButton } from '../../../../components/UI/StyledButton'
import { StyledPageTitle } from '../../../../components/UI/StyledPageTitle'
import { StyledUIMsg } from '../../../../components/UI/StyledUIMsg'
import { IoMdAdd } from 'react-icons/io'
import { NEWS } from '../../../../constant/routes'
import { ArticleTypes } from '../../../../interfaces/news.interface'

interface Props {
  articles: ArticleTypes[]
  isArticlesLoading: boolean
  articlesError: Error
}

const DashboardNews = ({ articles = [], isArticlesLoading, articlesError }: Props) => {
  const history = useHistory()

  const displayItems = articles.map(article => (
    <DashboardNewsItem key={article.id} title={article.title} date={article.published} id={article.id} />
  ))

  return (
    <DashboardNewsContainer>
      <StyledTitleWrapper>
        <StyledPageTitle>Τα Νέα</StyledPageTitle>
        <StyledButton onClick={() => history.push(NEWS.add)} publish>
          <IoMdAdd className='add-icon' /> Προσθήκη Νέου
        </StyledButton>
      </StyledTitleWrapper>
      <StyledDashboardItemsWrapper>
        {isArticlesLoading && <Spinner dashboard='center' />}
        {!isArticlesLoading && !articlesError && displayItems}
        {!isArticlesLoading && !articlesError && articles.length === 0 && (
          <StyledUIMsg>Δεν έχετε προσθέσει ακόμη κανένα άρθρο</StyledUIMsg>
        )}
        {!isArticlesLoading && articlesError && <StyledUIMsg>{articlesError.message}</StyledUIMsg>}
      </StyledDashboardItemsWrapper>
    </DashboardNewsContainer>
  )
}

export default DashboardNews

const DashboardNewsContainer = styled.div`
  width: 100%;
  height: 350px;
  padding: 30px 20px 30px 30px;
  background-color: var(--white);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  border-radius: 14px;

  @media (min-width: 1350px) {
    width: 100%;
    height: 100%;
  }
`

const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;

  button {
    margin-right: 10px;
  }
`

const StyledDashboardItemsWrapper = styled.div`
  height: 70%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  --ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`
