import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useSWRConfig } from 'swr'
import ConfirmActionModal from '../../components/Modal/ConfirmActionModal'
import ComplaintsTable from '../../components/Table/ComplaintsTable'
import Spinner from '../../components/UI/Spinners/Spinner'
import { StyledContainer } from '../../components/UI/StyledContainer'
import { StyledPageTitle, StyledPageTitleWrapper } from '../../components/UI/StyledPageTitle'
import { ComplaintTypes } from '../../interfaces/complaints.interface'
import complaintsService from '../../services/complaintsService'

interface Props {
  complaints: ComplaintTypes[]
  isLoading: boolean
  complaintsError: Error
}

const Complaints = ({ complaints, isLoading, complaintsError }: Props) => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [resouseId, setResourceId] = useState<number | null>(null)
  const { mutate } = useSWRConfig()
  useEffect(() => {
    document.title = 'Aegean Fresko | Παράπονα'
  }, [])

  const showModalHandler = (resourceId: number) => {
    setShowModal(true)
    setResourceId(resourceId)
  }

  const closeModalHandler = () => {
    setShowModal(false)
    setResourceId(null)
  }

  const onDeleteUserHandler = () => {
    complaintsService
      .deleteComplaint(resouseId!)
      .then(() => {
        mutate('complaints/complaint')
        toast.success('Το παράπονο διαγράφηκε επιτυχώς!')
      })
      .catch(error => {
        toast.error(error.message)
      })
      .finally(() => {
        closeModalHandler()
      })
  }

  return (
    <>
      <ConfirmActionModal
        deleteResource={onDeleteUserHandler}
        showModal={showModal}
        closeModalHandler={closeModalHandler}
        description='Είστε σίγουροι ότι θέλετε να διαγράψετε το παράπονο;'
      />
      <StyledContainer mainContent>
        <StyledPageTitleWrapper>
          <StyledPageTitle>Παράπονα</StyledPageTitle>
        </StyledPageTitleWrapper>

        {isLoading ? (
          <Spinner />
        ) : (
          <ComplaintsTable
            complaints={complaints}
            complaintsError={complaintsError}
            showModalHandler={showModalHandler}
          />
        )}
      </StyledContainer>
    </>
  )
}

export default Complaints
