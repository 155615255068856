import styled, { css } from "styled-components";

interface StyledProps {
  login?: boolean;
  publish?: boolean;
  draft?: boolean;
}

export const StyledButton = styled.button`
  border: none;
  border-radius: 15px;
  background-color: var(--primary-color);
  font-weight: var(--regular);
  color: var(--white);
  cursor: pointer;
  display: block;
  font-family: inherit;
  font-size: 1rem;
  outline: none;
  transition: all 200ms ease-out;

  &:focus,
  &:hover {
    opacity: 0.85;
  }

  &:disabled {
    background-color: var(--border-light-gray);
    cursor: not-allowed;
  }

  .add-icon {
    color: var(--white);
    font-weight: var(--bold);
    font-size: 13px;
    transform: translate(-1px, 1px);
  }

  ${({ login, publish, draft }: StyledProps) => {
    if (login) {
      return css`
        margin: 0 auto;
        padding: 16px 28px;
        font-weight: var(--bold);
        width: 100%;
      `;
    }

    if (publish) {
      return css`
        padding: 16px 28px;
        height: max-content;
      `;
    }

    if (draft) {
      return css`
        background-color: transparent;
        border: 1px solid var(--primary-color);
        color: var(--primary-color);
        width: 178px;
        max-height: 53px;

        &:focus,
        &:hover {
          background-color: var(--border-light-gray);
        }
      `;
    }
  }}
`;
