import React from "react";
import { Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import PdfContactInfo from "./PdfContactInfo";
import logo from "../../../../assets/login-logo.png";
import qrCode from "../../../../assets/qr-code.png";
import arrowIcon from "../../../../assets/arrow.png";
import bgImg from "../../../../assets/first-pdf-bg.png";
import moment from "moment";
import PdfProductsContainer from "./PdfProductsContainer";
import PdfProductCard from "./PdfProductCard";
import { ProductTypes } from "../../../../interfaces/products.interface";

const styles = StyleSheet.create({
  page: { fontFamily: "Isotext Pro" },
  section: {
    flexGrow: 1,
  },
  logoWrapper: {
    paddingVertical: 20,
    marginHorizontal: 35,
    borderBottom: "1px solid #AACE46",
    alignItems: "center",
  },
  logo: {
    width: 100,
  },
  titleWrapper: {
    alignItems: "center",
    marginVertical: 20,
  },
  title: {
    fontSize: 32,
    textAlign: "center",
  },
  date: {
    fontSize: 18,
    color: "#00AFEF",
  },
  downloadAppWrapper: {
    borderTop: "1px solid #AACE46",
    padding: "18px 0",
    flexDirection: "row",
    justifyContent: "center",
  },
  text: {
    width: 259,
    fontSize: 14,
    color: "#4A4A4A",
  },
  qrCode: {
    width: 62,
    height: 62,
    marginRight: 16,
  },
  arrowIcon: {
    width: 20,
    height: 18,
    marginTop: 12,
  },
  backgroundImage: {
    position: "absolute",
    width: "100%",
    height: "617",
    bottom: 0,
    left: 0,
    right: 0,
  },
  row: {
    flexDirection: "row",
  },
  cell: {
    width: "33.33%",
    padding: 5,
  },
});

interface Props {
  title: string;
  startDate: string;
  expirationDate: string;
  products: ProductTypes[];
  discount: number;
}

const FirstPdfPage = ({
  title,
  startDate,
  expirationDate,
  products = [],
  discount,
}: Props) => {
  const formatDate = (date: string) => moment(date).format("DD/MM/YYYY");

  return (
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Image src={bgImg} style={styles.backgroundImage} />
        <View style={styles.logoWrapper}>
          <Image src={logo} style={styles.logo} />
        </View>
        <View style={styles.titleWrapper}>
          <Text style={styles.title}>{title}</Text>
          <Text
            style={styles.date}
          >{`ΙΣΧΥΕΙ ΑΠΟ ${formatDate(startDate)} ΕΩΣ ${expirationDate?.replaceAll(".", "/")}`}</Text>
        </View>
        <PdfProductsContainer>
          {products.map((product, index) =>
            index % 3 === 0 ? (
              <View style={styles.row} key={index}>
                {products[index] && (
                  <View style={styles.cell}>
                    <PdfProductCard
                      product={products[index]}
                      discount={discount}
                    />
                  </View>
                )}
                {products[index + 1] && (
                  <View style={styles.cell}>
                    <PdfProductCard
                      product={products[index + 1]}
                      discount={discount}
                    />
                  </View>
                )}
                {products[index + 2] && (
                  <View style={styles.cell}>
                    <PdfProductCard
                      product={products[index + 2]}
                      discount={discount}
                    />
                  </View>
                )}
              </View>
            ) : null,
          )}
        </PdfProductsContainer>
        <View
          style={{
            padding: "0 35px 13px",
            // marginTop: "auto",
          }}
        >
          <View style={styles.downloadAppWrapper}>
            <Image src={qrCode} style={styles.qrCode} />
            <View>
              <Text></Text>
              <View>
                <Text style={styles.text}>
                  Οι προσφορές ισχύουν μόνο για παραγγελίες μέσω της εφαρμογής
                  B2B
                </Text>
                <Image style={styles.arrowIcon} src={arrowIcon} />
              </View>
            </View>
          </View>
          <PdfContactInfo />
        </View>
      </View>
    </Page>
  );
};

export default FirstPdfPage;
