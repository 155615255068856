import { Route } from 'react-router-dom'
import { DASHBOARD } from '../../constant/routes'
import { ArticleTypes } from '../../interfaces/news.interface'
import Dashboard from '../../pages/Dashboard/Dashboard'

interface Props {
  articles: ArticleTypes[]
  isLoading: boolean
  articlesError: Error
}

const DashboardRoutes = ({ articles = [], isLoading, articlesError }: Props) => {
  return (
    <Route path={DASHBOARD} exact>
      <Dashboard articles={articles} isArticlesLoading={isLoading} articlesError={articlesError} />
    </Route>
  )
}

export default DashboardRoutes
