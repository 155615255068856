import { useParams, useHistory } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import { useSWRConfig } from 'swr'
import SalesForm from './components/SalesForm'
import { StyledContainer } from '../../components/UI/StyledContainer'
import { StyledPageSubtitle, StyledPageTitle } from '../../components/UI/StyledPageTitle'
import { authContext } from '../../context/auth-context'
import { SalesFormTypes } from '../../interfaces/sales-form.interface'
import { updateResource } from '../../helpers/updateResource'
import { useOffersCtx } from '../../context/offers-context'
import SalesModalsContainer from './components/SalesModalsContainer'

const EditSale = () => {
  const { offers, setSelectedProducts, setSelectedUsers, selectedProducts, selectedUsers, onResetHandler } =
    useOffersCtx()
  const [isFormLoading, setIsFormLoading] = useState<boolean>(false)
  const params: { id: string } = useParams()
  const { token, destroyToken } = useContext(authContext)
  const { mutate } = useSWRConfig()
  const history = useHistory()
  const sale = offers.data?.find(sale => sale.id === +params.id)

  const onSubmit = async (values: SalesFormTypes) => {
    setIsFormLoading(true)
    const url = `offers/offer/${params.id}`
    const editSale = new FormData()
    const method = values.image === sale?.image ? 'PATCH' : 'PUT'

    if (method === 'PATCH') {
      if (values.title !== sale?.title) editSale.append('title', values.title)
      if (+values.discount !== sale?.discount) editSale.append('discount', values.discount)
      if (values.expiration_date !== sale?.expiration_date)
        editSale.append('expiration_date', values.expiration_date)
      if (values.description !== sale?.description) editSale.append('description', values.description)
      editSale.append('start_date', values.startDate)
    }

    if (method === 'PUT') {
      editSale.append('title', values.title)
      editSale.append('description', values.description)
      editSale.append('image', values.image)
      editSale.append('discount', values.discount)
      editSale.append('start_date', values.startDate)
      editSale.append('expiration_date', values.expiration_date)
    }

    for (const product of selectedProducts) editSale.append('products', product.productid.toString())
    if (selectedUsers.length > 0) {
      for (const user of selectedUsers) editSale.append('customers', user.id.toString())
    }

    updateResource({
      url,
      method,
      formData: editSale,
      token,
      setIsFormLoading,
      mutate,
      destroyToken,
      history,
    })
  }

  // Initial state of selected products and users
  useEffect(() => {
    if (sale) {
      setSelectedProducts(sale.products)
      setSelectedUsers(sale.customers)
    }
    // Reset selected products and users on unmount
    return () => onResetHandler()
  }, [sale, setSelectedProducts, setSelectedUsers, onResetHandler])

  return (
    <>
      <StyledContainer formContent>
        <StyledPageTitle>Επεξεργασία Προσφοράς</StyledPageTitle>
        <StyledPageSubtitle>{sale?.title}</StyledPageSubtitle>
        <SalesForm sale={sale} onSubmit={onSubmit} isFormLoading={isFormLoading} />
      </StyledContainer>
      <SalesModalsContainer />
    </>
  )
}

export default EditSale
