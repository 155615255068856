import React from "react";
import { ProductTypes } from "../../../../interfaces/products.interface";
import { View, StyleSheet, Text, Image } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  shadowOverlay: {
    backgroundColor: "#00000005",
    borderRadius: 20,
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    maxHeight: 185,
    position: "relative",
  },
  container: {
    backgroundColor: "#fff",
    borderRadius: 20,
    width: "98%",
    height: "97%",
    zIndex: -1,
  },
  discount: {
    top: 9,
    left: 11,
    position: "absolute",
    borderRadius: "8px",
    backgroundColor: "#AACE46",
    width: 30,
    height: 23,
    justifyContent: "center",
    alignItems: "center",
  },
  discountText: {
    color: "#fff",
    fontSize: 12,
    marginTop: 4,
  },
  productCodeText: {
    fontSize: 12,
  },
  productNameText: {
    textAlign: "center",
    fontSize: 12,
    marginVertical: 2,
  },
  priceWrapper: {
    flexDirection: "row",
  },
  currentPriceText: {
    fontSize: 11,
    color: "#00506D",
  },
  newPriceText: {
    fontSize: 13,
    color: "#00AFEF",
  },
  unit: {
    fontSize: 11,
    color: "#00506D",
    marginLeft: 3,
  },
});

const unitsShort: { [key: string]: string } = {
  Τεμάχια: "ΤΜΧ",
  Κιλά: "ΚΙΛ",
  Κιβώτια: "KIB",
  Πακέττο: "ΠΑΚ",
};

interface Props {
  product: ProductTypes;
  discount: number;
}

const PdfProductCard = ({ product, discount }: Props) => {
  const priceAfterDiscount = product.pricew - product.pricew * (discount / 100);
  return (
    <>
      <View style={styles.shadowOverlay}>
        {discount > 0 && (
          <View style={styles.discount}>
            <Text style={styles.discountText}>{discount}%</Text>
          </View>
        )}
        <View style={styles.container}>
          <Image
            style={{
              position: "relative",
              width: "65%",
              height: 100,
              margin: "0 auto",
            }}
            src={`${product.product_photo?.replace("http", "https")}`}
          />
          <View
            style={{
              alignItems: "center",
              padding: 10,
              marginTop: "auto",
            }}
          >
            <Text style={styles.productNameText}>{product.name}</Text>
            <View style={styles.priceWrapper}>
              {discount > 0 && (
                <Text style={styles.newPriceText}>
                  {`${priceAfterDiscount?.toFixed(2)}€`}{" "}
                </Text>
              )}
              <Text
                style={{
                  ...styles.currentPriceText,
                  textDecoration: discount > 0 ? "line-through" : "none",
                }}
              >
                {product.pricew?.toFixed(2)}€
              </Text>
              <Text
                style={styles.unit}
              >{`/ ${unitsShort[product.unit_info?.[0]?.title]}`}</Text>
            </View>
          </View>
        </View>
      </View>
    </>
  );
};

export default PdfProductCard;
