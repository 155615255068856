import { toast } from 'react-toastify'

interface Types {
  url: string
  token: string | null
  mutate: (value: string) => void
  destroyToken: () => void
}

export const deleteResourceById = async ({ url, token, mutate, destroyToken }: Types) => {
  const resource = url.startsWith('news/article') ? 'Το άρθρο' : 'Η προσφορά'
  try {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/${url}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    if (response.ok) {
      toast.success(`${resource} διαγράφηκε επιτυχώς`)
      mutate(url.startsWith('news/article') ? 'news/article' : 'offers/offer')
    }

    if (response.status === 401) {
      destroyToken()
      throw new Error('Η σύνδεση σας έχει λήξει. Παρακαλώ συνδεθείτε ξανά.')
    }

    if (!response.ok) {
      throw new Error(`Αποτυχία διαγραφής εγγραφής. Παρακαλώ δοκιμάστε ξανά.`)
    }
  } catch (err) {
    if (err instanceof Error) {
      toast.error(err.message)
    }
  }
}
