import { Route, Switch } from "react-router-dom";
import Layout from "./containers/Layout/Layout";
import Login from "./pages/Login/Login";
import useFetch from "./hooks/useFetch";
import { LOGIN, REDIRECT } from "./constant/routes";
import { BflowUserTypes } from "./interfaces/bflowUser.interface";
import { ProductTypes } from "./interfaces/products.interface";
import OffersProvider from "./context/offers-context";
import SalesRoutes from "./components/Routes/SalesRoutes";
import { SwrDataTypes } from "./interfaces/generics.interface";
import { ArticleTypes } from "./interfaces/news.interface";
import ErpUsersRoutes from "./components/Routes/ErpUsersRoutes";
import ArticlesRoutes from "./components/Routes/ArticlesRoutes";
import ComplaintsRoutes from "./components/Routes/ComplaintsRoutes";
import MessagesRoutes from "./components/Routes/MessagesRoutes";
import BflowUsersRoutes from "./components/Routes/BflowUserRoutes";
import ProductsRoutes from "./components/Routes/ProductsRoutes";
import DashboardRoutes from "./components/Routes/DashboardRoutes";
import RedirectToMobileStore from "./pages/RedirectToMobileStore/RedirectToMobileStore";

const App = () => {
  const {
    data: articles,
    error: articlesError,
    isLoading: isArticlesLoading,
  }: SwrDataTypes<ArticleTypes> = useFetch("news/article");

  const {
    data: bflowUsers,
    error: bflowUsersError,
    isLoading: isBflowUsersLoading,
  }: SwrDataTypes<BflowUserTypes> = useFetch("auth/customer");

  const {
    data: products,
    isLoading: isProductsLoading,
    error: productsError,
  }: SwrDataTypes<ProductTypes> = useFetch("products/product");

  return (
    <>
      <Switch>
        <Route path={REDIRECT} exact>
          <RedirectToMobileStore />
        </Route>

        <Route path={LOGIN} exact>
          <Login />
        </Route>
        <Layout>
          <OffersProvider bflowUsers={bflowUsers} products={products}>
            <DashboardRoutes
              articles={articles}
              isLoading={isArticlesLoading}
              articlesError={articlesError}
            />
            <SalesRoutes />
          </OffersProvider>
          <ArticlesRoutes
            articles={articles}
            isLoading={isArticlesLoading}
            articlesError={articlesError}
          />
          <ErpUsersRoutes />
          <BflowUsersRoutes
            bflowUsers={bflowUsers}
            isLoading={isBflowUsersLoading}
            bflowUsersError={bflowUsersError}
          />
          <ComplaintsRoutes />
          <MessagesRoutes bflowUsers={bflowUsers} />
          <ProductsRoutes
            products={products}
            productsError={productsError}
            isLoading={isProductsLoading}
          />
        </Layout>
      </Switch>
    </>
  );
};

export default App;
