import { useMemo } from "react";
import { BsArrowCounterclockwise } from "react-icons/bs";
import { BiReset } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import { BflowUserTypes } from "../../interfaces/bflowUser.interface";
import { StyledActionsWrapper, StyledTableContainer } from "../UI/StyledTable";
import TableItems from "./TableItems/TableItems";
import styled from "styled-components";
import moment from "moment";

interface OriginalIdType {
  cell: {
    row: {
      original: {
        erp_id: number;
        id: number;
        email: string;
        username: string;
      };
    };
  };
}

interface Props {
  bflowUsers: BflowUserTypes[];
  bflowUsersError: Error;
  showModalHandler: (resourceId: number) => void;
  onResetPasswordHandler: (username: string, email: string) => void;
  onResetUsernameHandler: (email: string) => void;
}

const BflowUsersTable = ({
  bflowUsers = [],
  bflowUsersError,
  showModalHandler,
  onResetPasswordHandler,
  onResetUsernameHandler,
}: Props) => {
  const columns = useMemo(
    () => [
      {
        Header: "Πελάτης",
        accessor: "customer_name",
        Cell: ({ value }: { value: string }) => (
          <span title={value}>{value || "-"}</span>
        ),
      },

      {
        Header: "ΑΦΜ",
        accessor: "afm",
      },
      {
        Header: "Όνομα χρήστη",
        accessor: "username",
        // Cell: ({ value }: { value: string }) => <p>{value || '-'}</p>,
      },
      {
        Header: "Email",
        accessor: "email",
        Cell: ({ value }: { value: string }) => <span>{value || "-"}</span>,
      },
      {
        Header: "Τηλέφωνο",
        accessor: "phone",
        Cell: ({ value }: { value: string }) => <span>{value || "-"}</span>,
      },
      {
        Header: "Συχνότητα",
        accessor: "user.login_frequency",
        Cell: ({ value }: { value: string }) => (
          <p style={{ textAlign: "center" }}>{`${value}/7`}</p>
        ),
      },
      {
        Header: "Τελευταία σύνδεση",
        accessor: "user.last_login",
        Cell: ({ value }: { value: string }) => (
          <span>{value ? moment(value).format("DD/MM/YYYY HH:mm") : "-"}</span>
        ),
      },
      {
        Header: "Ενέργειες",
        Cell: ({
          cell: {
            row: { original },
          },
        }: OriginalIdType) => {
          return (
            <StyledActionsWrapper>
              <button
                style={{ all: "unset" }}
                disabled={!!!original.email}
                onClick={() =>
                  onResetPasswordHandler(original.username, original.email)
                }
              >
                <StyledP title="Επαναφορά κωδικού">
                  <BiReset
                    size={18}
                    color={!!!original.email ? "#BDBDBD" : "#000"}
                    cursor={!!!original.email ? "not-allowed" : "pointer"}
                  />
                </StyledP>
              </button>
              <button
                style={{ all: "unset" }}
                onClick={() => onResetUsernameHandler(original.email)}
              >
                <StyledP title="Επαναφορά όνομα χρήστη">
                  <BsArrowCounterclockwise
                    size={18}
                    color="#000"
                    cursor="pointer"
                  />
                </StyledP>
              </button>
              <MdDelete
                size={18}
                cursor="pointer"
                color="#ff3434"
                onClick={() => showModalHandler(original.id)}
              />
            </StyledActionsWrapper>
          );
        },
      },
    ],
    [showModalHandler, onResetPasswordHandler, onResetUsernameHandler],
  );

  return (
    <StyledTableContainer>
      <TableItems
        data={bflowUsers}
        columns={columns}
        errorMessage={bflowUsersError}
        pageSize={20}
      />
    </StyledTableContainer>
  );
};

export default BflowUsersTable;

const StyledP = styled.p`
  display: flex;
  align-items: center;
  gap: 4px;
`;
