import styled from 'styled-components'

interface Props {
  onClickHandler: () => void
  text: string
}

const FIlterButton = ({ onClickHandler, text }: Props) => {
  return (
    <StyledFilterButton onClick={onClickHandler} type='button'>
      {text}
    </StyledFilterButton>
  )
}

export default FIlterButton

const StyledFilterButton = styled.button`
  border: none;
  border-radius: 15px;
  background-color: var(--primary-color);
  font-weight: var(--regular);
  color: var(--white);
  cursor: pointer;
  display: block;
  font-family: inherit;
  font-size: 0.875rem;
  outline: none;
  padding: 10px 20px;
  transition: all 200ms ease-out;
  &:hover {
    opacity: 0.9;
  }
`
