import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { StyledButton } from '../../components/UI/StyledButton'
import { StyledContainer } from '../../components/UI/StyledContainer'
import { StyledPageTitle, StyledPageTitleWrapper } from '../../components/UI/StyledPageTitle'
import { IoMdAdd } from 'react-icons/io'
import Spinner from '../../components/UI/Spinners/Spinner'
import { NEWS } from '../../constant/routes'
import ArticlesTable from '../../components/Table/ArticlesTable'
import { ArticleTypes } from '../../interfaces/news.interface'

interface Props {
  articles: ArticleTypes[]
  isLoading: boolean
  articlesError: Error
}

const News = ({ articles = [], isLoading, articlesError }: Props) => {
  const history = useHistory()

  useEffect(() => {
    document.title = 'Aegean Fresko | News'
  }, [])

  return (
    <StyledContainer mainContent>
      <StyledPageTitleWrapper>
        <StyledPageTitle>Νέα - Άρθρα</StyledPageTitle>
        <StyledButton onClick={() => history.push(NEWS.add)} publish>
          <IoMdAdd className='add-icon' />
          Προσθήκη Νέου
        </StyledButton>
      </StyledPageTitleWrapper>

      {isLoading ? <Spinner /> : <ArticlesTable articles={articles} articlesError={articlesError} />}
    </StyledContainer>
  )
}

export default News
