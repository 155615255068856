import moment from 'moment'
import { useMemo } from 'react'
import { MdDelete } from 'react-icons/md'
import styled from 'styled-components'
import { MessageTypes } from '../../interfaces/messages.interface'
import { StyledActionsWrapper, StyledTableContainer } from '../UI/StyledTable'
import TableItems from './TableItems/TableItems'

interface Props {
  messages: MessageTypes[]
  messagesError?: Error
  showModalHandler: (resourceId: number) => void
}

interface OriginalIdType {
  cell: {
    row: {
      original: {
        id: number
      }
    }
  }
}

const MessagesTable = ({ messages = [], messagesError, showModalHandler }: Props) => {
  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
      },
      {
        Header: 'Θέμα',
        accessor: 'subject',
        Cell: ({ value }: { value: string }) => <StyledP title={value}>{value}</StyledP>,
      },
      {
        Header: 'Μήνυμα',
        accessor: 'text',
        Cell: ({ value }: { value: string }) => <StyledP title={value}>{value}</StyledP>,
      },
      {
        Header: 'Ημερομηνία',
        accessor: 'created_date',
        Cell: ({ value }: { value: string }) => <span>{moment(value).format('DD/MM/YY, HH:mm')}</span>,
      },
      {
        Header: 'Ενέργειες',
        Cell: ({
          cell: {
            row: { original },
          },
        }: OriginalIdType) => {
          return (
            <StyledActionsWrapper>
              <MdDelete
                size={18}
                cursor='pointer'
                color='#ff3434'
                onClick={() => showModalHandler(original.id)}
              />
            </StyledActionsWrapper>
          )
        },
      },
    ],
    [showModalHandler]
  )

  return (
    <StyledTableContainer>
      <TableItems data={messages} columns={columns} errorMessage={messagesError} />
    </StyledTableContainer>
  )
}

export default MessagesTable

const StyledP = styled.p`
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
