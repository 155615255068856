import { AiFillCloseCircle } from 'react-icons/ai'
import styled from 'styled-components'
import { StyledPageTitle } from '../UI/StyledPageTitle'

interface Props {
  title: string
  modalClickHandler: () => void
  children?: React.ReactNode
}
const ModalTitle = ({ title, modalClickHandler, children }: Props) => {
  return (
    <StyledHeaderWrapper>
      <div className='selected-items-wrapper'>
        <StyledPageTitle>{title}</StyledPageTitle>
        {children}
      </div>
      <AiFillCloseCircle onClick={modalClickHandler} size={24} />
    </StyledHeaderWrapper>
  )
}

export default ModalTitle

const StyledHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .selected-items-wrapper {
    display: flex;
    gap: 2rem;
  }

  svg {
    transition: all 200ms;
    cursor: pointer;
  }
  svg:hover {
    color: var(--primary-color);
  }
`
