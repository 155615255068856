import { CreateRecipientsTypes } from '../interfaces/messages.interface'

class MessagesService {
  async createMessage({ values, recipients, token }: CreateRecipientsTypes) {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/privateMessages/pm/`, {
        method: 'POST',
        body: JSON.stringify({
          subject: values.subject,
          text: values.message,
          customers: recipients.map(recipient => recipient.id),
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      if (!response.ok) {
        throw Error
      }
    } catch (error) {
      throw new Error('Αποτυχία αποστολής μηνύματος')
    }
  }

  async deleteMessage(id: number, token: string) {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/privateMessages/pm/${id}/`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      if (!response.ok) {
        throw Error
      }
    } catch (error) {
      throw new Error('Αποτυχία διαγραφή μηνύματος')
    }
  }
}

export default new MessagesService()
