import { BFLOWUSERS, COMPLAINTS, ERPUSERS, MESSAGES, NEWS, PRODUCTS, SALES } from '../constant/routes'

export const getText = (pathname: string) => {
  switch (pathname) {
    case NEWS.main:
      return 'Άρθρο'
    case SALES.main:
      return 'Προσφορά'
    case COMPLAINTS.main:
      return 'Παράπονο'
    case MESSAGES.main:
      return 'Μήνυμα'
    case PRODUCTS.main:
      return 'Προϊόν'
    default:
      return 'Χρήστης'
  }
}

export const getTextPlural = (pathname: string) => {
  switch (pathname) {
    case NEWS.main:
      return 'Άρθρα'
    case SALES.main:
      return 'Προσφορές'
    case COMPLAINTS.main:
      return 'Παράπονα'
    case MESSAGES.main:
      return 'Μηνύματα'
    case PRODUCTS.main:
      return 'Προϊόντα'
    default:
      return 'Χρήστες'
  }
}

export const getNoResultMessage = (pathname: string) => {
  switch (pathname) {
    case NEWS.main:
      return 'Δεν βρέθηκαν άρθρα'
    case SALES.main:
      return 'Δεν βρέθηκαν προσφορές'
    case COMPLAINTS.main:
      return 'Δεν βρέθηκαν παράπονα'
    case BFLOWUSERS.main:
      return 'Δεν βρέθηκαν BFLOW χρήστες'
    case ERPUSERS.main:
      return 'Δεν βρέθηκαν ERP χρήστες'
    case MESSAGES.main:
      return 'Δεν βρέθηκαν μηνύματα'
    case PRODUCTS.main:
      return 'Δεν βρέθηκαν προϊόντα'
    default:
      return ''
  }
}

export const getErrorMessage = (pathname: string) => {}
