import { useEffect, useRef } from 'react'
import { IoMdAddCircleOutline } from 'react-icons/io'
import { HiOutlineUserCircle } from 'react-icons/hi'
import { useFormik } from 'formik'
import FormSpinner from '../../../components/UI/Spinners/FormSpinner'
import * as Styled from '../../../components/UI/StyledForm'
import { StyledButton } from '../../../components/UI/StyledButton'
import { StyledDivider } from '../../../components/UI/StyledDivider'
import * as yup from 'yup'
import { MessageFormTypes } from '../../../interfaces/messages.interface'
import { BflowUserTypes } from '../../../interfaces/bflowUser.interface'

interface Props {
  selectedUsers: BflowUserTypes[]
  onSubmit: (values: MessageFormTypes) => void
  isFormLoading: boolean
  showUsersModalHandler: () => void
}

const MessageForm = ({ selectedUsers = [], onSubmit, showUsersModalHandler, isFormLoading }: Props) => {
  const inputFocus = useRef<HTMLInputElement>(null)
  const recipientsText = selectedUsers.length === 1 ? 'Παραλήπτης' : 'Παραλήπτες'
  const initialValues = {
    subject: '',
    message: '',
  }

  const validationSchema = yup.object({
    subject: yup.string().trim().required(),
    message: yup.string().trim().required(),
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  })

  useEffect(() => inputFocus.current?.focus(), [])

  return (
    <Styled.Form onSubmit={formik.handleSubmit} news>
      <Styled.InputWrapper news>
        <Styled.Label news htmlFor='subject' error={!!(formik.touched.subject && formik.errors.subject)}>
          Θέμα:
        </Styled.Label>
        <div>
          <Styled.Input
            news
            id='subject'
            type='text'
            placeholder='Θέμα'
            error={!!(formik.touched.subject && formik.errors.subject)}
            ref={inputFocus}
            {...formik.getFieldProps('subject')}
          />
        </div>
      </Styled.InputWrapper>
      <Styled.InputWrapper news>
        <Styled.Label
          news
          textArea
          htmlFor='message'
          error={!!(formik.touched.message && formik.errors.message)}
        >
          Μήνυμα:
        </Styled.Label>
        <Styled.TextArea
          id='message'
          placeholder='Μήνυμα'
          rows={8}
          error={!!(formik.touched.message && formik.errors.message)}
          {...formik.getFieldProps('message')}
        />
      </Styled.InputWrapper>
      <Styled.InputWrapper news>
        <Styled.Label news textArea htmlFor='message'>
          Παραλήπτες:
        </Styled.Label>
        <Styled.FormModalButton onClick={showUsersModalHandler} type='button'>
          {selectedUsers.length > 0 ? (
            <HiOutlineUserCircle color='#00AFEF' size={20} />
          ) : (
            <IoMdAddCircleOutline color='#00AFEF' size={20} />
          )}
          {selectedUsers.length > 0 ? `${selectedUsers.length} ${recipientsText}` : 'Προσθέστε παραλήπτες'}
        </Styled.FormModalButton>
      </Styled.InputWrapper>
      <StyledDivider />
      <Styled.FormBottomWrapper>
        <Styled.FormButtonWrapper>
          <StyledButton publish type='submit'>
            {isFormLoading ? <FormSpinner /> : 'Aποστολή'}
          </StyledButton>
        </Styled.FormButtonWrapper>
      </Styled.FormBottomWrapper>
    </Styled.Form>
  )
}

export default MessageForm
