import { useEffect, useState } from "react";
import ProductsTable from "../../components/Table/ProductsTable";
import Spinner from "../../components/UI/Spinners/Spinner";
import { StyledContainer } from "../../components/UI/StyledContainer";
import {
  StyledPageTitle,
  StyledPageTitleWrapper,
} from "../../components/UI/StyledPageTitle";
import { ProductTypes } from "../../interfaces/products.interface";
import FIlterButton from "./components/FIlterButton";
import styled from "styled-components";

interface Props {
  products: ProductTypes[];
  isLoading: boolean;
  productsError?: Error;
}

const Products = ({ products = [], isLoading, productsError }: Props) => {
  const [allProducts, setAllProducts] = useState<ProductTypes[]>([]);

  useEffect(() => {
    document.title = "Aegean Fresko | Προϊόντα";
  }, []);

  useEffect(() => {
    if (allProducts.length === 0) {
      setAllProducts(products);
    }
  }, [products, allProducts]);

  const filterByNoImage = () => {
    setAllProducts((currProducts) => [
      ...currProducts.sort(
        (a: { product_photo: string }, b: { product_photo: string }) => {
          if (a.product_photo === null) return -1;
          if (b.product_photo === null) return 1;
          return 0;
        },
      ),
    ]);
  };

  const filterByLastEdited = () => {
    setAllProducts((currProducts) => [
      ...currProducts.sort(
        (
          a: { date_edited: string | null },
          b: { date_edited: string | null },
        ) => {
          if (a.date_edited !== null) return -1;
          if (b.date_edited !== null) return 1;
          return 0;
        },
      ),
    ]);
  };

  return (
    <StyledContainer mainContent>
      <StyledPageTitleWrapper>
        <StyledPageTitle>Προϊόντα</StyledPageTitle>
      </StyledPageTitleWrapper>
      <StyledFilterContainer>
        <FIlterButton onClickHandler={filterByNoImage} text="Χωρίς Εικόνα" />
        <FIlterButton
          onClickHandler={filterByLastEdited}
          text="Τελευταία Επεξεργασία"
        />
      </StyledFilterContainer>
      {isLoading ? (
        <Spinner />
      ) : (
        <ProductsTable
          products={allProducts}
          productsError={productsError}
          pageSize={20}
        />
      )}
    </StyledContainer>
  );
};

export default Products;

const StyledFilterContainer = styled.div`
  position: absolute;
  display: flex;
  gap: 10px;
  top: 70px;
`;
