import { useFormik } from 'formik'
import FormSpinner from '../../../components/UI/Spinners/FormSpinner'
import * as Styled from '../../../components/UI/StyledForm'
import { StyledButton } from '../../../components/UI/StyledButton'
import { StyledDivider } from '../../../components/UI/StyledDivider'
import * as yup from 'yup'
import { ErpUserFormTypes } from '../../../interfaces/erpUser.interface'

interface Props {
  erpId: string
  onSubmit: (values: ErpUserFormTypes) => void
  isFormLoading: boolean
}

const ErpUserForm = ({ erpId, isFormLoading, onSubmit }: Props) => {
  const initialValues: ErpUserFormTypes = {
    erpId,
    username: '',
    password: '',
    confirmPassword: '',
  }

  const validationSchema = yup.object({
    username: yup.string().trim().required('Απαιτείται όνομα χρήστη'),
    password: yup.string().trim().required('Απαιτείται κωδικός πρόσβασης'),
    confirmPassword: yup
      .string()
      .trim()
      .required('Απαιτείται επιβεβαίωση κωδικού πρόσβασης')
      .oneOf([yup.ref('password')], 'Οι κωδικοί πρέπει να ταιριάζουν'),
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  })

  return (
    <Styled.Form onSubmit={formik.handleSubmit} news>
      <Styled.InputWrapper news>
        <Styled.Label news>ERP ID:</Styled.Label>
        <div>
          <p>{erpId}</p>
        </div>
      </Styled.InputWrapper>
      <Styled.InputWrapper news>
        <Styled.Label news htmlFor='username' error={!!(formik.touched.username && formik.errors.username)}>
          Όνομα χρήστη:
        </Styled.Label>
        <div>
          <Styled.Input
            news
            id='username'
            type='text'
            placeholder='Όνομα χρήστη'
            error={!!(formik.touched.username && formik.errors.username)}
            {...formik.getFieldProps('username')}
          />
          {formik.touched.username && formik.errors.username && (
            <Styled.ErrorInputMsg error>{formik.errors.username}</Styled.ErrorInputMsg>
          )}
        </div>
      </Styled.InputWrapper>
      <Styled.InputWrapper news>
        <Styled.Label news htmlFor='password' error={!!(formik.touched.password && formik.errors.password)}>
          Κωδικός πρόσβασης:
        </Styled.Label>
        <div>
          <Styled.Input
            news
            id='password'
            type='password'
            placeholder='Κωδικός πρόσβασης'
            error={!!(formik.touched.password && formik.errors.password)}
            {...formik.getFieldProps('password')}
          />
          {formik.touched.password && formik.errors.password && (
            <Styled.ErrorInputMsg error>{formik.errors.password}</Styled.ErrorInputMsg>
          )}
        </div>
      </Styled.InputWrapper>
      <Styled.InputWrapper news>
        <Styled.Label
          news
          htmlFor='confirmPassword'
          error={!!(formik.touched.confirmPassword && formik.errors.confirmPassword)}
        >
          Επιβεβαίωση κωδικού:
        </Styled.Label>
        <div>
          <Styled.Input
            news
            id='confirmPassword'
            type='password'
            placeholder='Επιβεβαίωση κωδικού'
            error={!!(formik.touched.confirmPassword && formik.errors.confirmPassword)}
            {...formik.getFieldProps('confirmPassword')}
          />
          {formik.touched.confirmPassword && formik.errors.confirmPassword && (
            <Styled.ErrorInputMsg error>{formik.errors.confirmPassword}</Styled.ErrorInputMsg>
          )}
        </div>
      </Styled.InputWrapper>
      <StyledDivider />
      <Styled.FormBottomWrapper>
        <div />
        <Styled.FormButtonWrapper>
          <StyledButton publish type='submit'>
            {isFormLoading ? <FormSpinner /> : 'Υποβολή'}
          </StyledButton>
        </Styled.FormButtonWrapper>
      </Styled.FormBottomWrapper>
    </Styled.Form>
  )
}

export default ErpUserForm
