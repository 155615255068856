import { Route } from 'react-router-dom'
import { NEWS } from '../../constant/routes'
import { ArticleTypes } from '../../interfaces/news.interface'
import AddArticle from '../../pages/News/AddArticle'
import EditArticle from '../../pages/News/EditArticle'
import News from '../../pages/News/News'

interface Props {
  articles: ArticleTypes[]
  isLoading: boolean
  articlesError: Error
}

const ArticlesRoutes = ({ articles = [], isLoading, articlesError }: Props) => {
  return (
    <>
      <Route path={NEWS.main} exact>
        <News articles={articles} isLoading={isLoading} articlesError={articlesError} />
      </Route>
      <Route path={NEWS.add} exact>
        <AddArticle />
      </Route>
      <Route path={`${NEWS.edit}/:id`} exact>
        <EditArticle articles={articles} />
      </Route>
    </>
  )
}

export default ArticlesRoutes
