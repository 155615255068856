import React, { ReactNode } from "react";
import { View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  container: {
    padding: "20px 35px",
  },
});

const PdfProductsContainer = ({ children }: { children: ReactNode }) => {
  return <View style={styles.container}>{children}</View>;
};

export default PdfProductsContainer;
