import { Document, PDFViewer, Font } from "@react-pdf/renderer";
import LastPdfPage from "./LastPdfPage";
// @ts-ignore
import IsoTextPro from "../../../../assets/fonts/Isotext-Pro-Regular.ttf";
import FirstPdfPage from "./FirstPdfPage";
import { useOffersCtx } from "../../../../context/offers-context";
import { useParams } from "react-router-dom";
import { ProductTypes } from "../../../../interfaces/products.interface";
import ProductsPdfPage from "./ProductsPdfPage";
import { useMemo } from "react";

Font.register({
  family: "Isotext Pro",
  src: IsoTextPro,
});

const OfferPdf = () => {
  const { offers } = useOffersCtx();
  const params: { id: string } = useParams();
  const sale = offers.data?.find((sale) => sale.id === +params.id);

  const chunkArray = (
    array: ProductTypes[],
    firstChunkSize: number,
    otherChunkSize: number,
  ) => {
    const newArray = [...array];
    const results = [];
    if (newArray.length) {
      results.push(newArray.splice(0, firstChunkSize));
    }
    while (newArray.length) {
      results.push(newArray.splice(0, otherChunkSize));
    }
    return results;
  };

  const sortedProductByCode = useMemo(
    () => sale?.products.sort((a, b) => +a.code - +b.code),
    [sale],
  );

  const productChunks = chunkArray(sortedProductByCode ?? [], 6, 12);

  return sale ? (
    <PDFViewer width="100%" height="750">
      <Document>
        <FirstPdfPage
          title={sale.title}
          startDate={sale.start_date}
          expirationDate={sale.expiration_date}
          discount={sale.discount}
          products={productChunks[0]}
        />
        {productChunks.slice(1).map((products, index) => (
          <ProductsPdfPage
            key={index}
            products={products}
            discount={sale.discount}
          />
        ))}
        <LastPdfPage />
      </Document>
    </PDFViewer>
  ) : null;
};

export default OfferPdf;
