import { useContext } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import HeaderButton from './HeaderButton/HeaderButton'
import HeaderPaths from './HeaderPaths/HeaderPaths'
import { authContext } from '../../context/auth-context'

const Header = () => {
  const location = useLocation()
  const { destroyToken } = useContext(authContext)

  const logoutHandler = () => {
    destroyToken()
  }

  return (
    <StyledHeader>
      <StyledHeaderTopWrapper>
        <StyledHeaderTitle>AEGEAN FRESKO Διαχείριση</StyledHeaderTitle>
        <div>
          <HeaderButton onClickHandler={logoutHandler} text='Αποσύνδεση' />
        </div>
      </StyledHeaderTopWrapper>
      <StyledHeaderBottomWrapper>
        <HeaderPaths path={location.pathname} />
      </StyledHeaderBottomWrapper>
    </StyledHeader>
  )
}

export default Header

const StyledHeader = styled.header`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const StyledHeaderTopWrapper = styled.div`
  width: 100%;
  background-color: var(--primary-color);
  height: 50px;
  display: flex;
  justify-content: space-between;
`

const StyledHeaderBottomWrapper = styled(StyledHeaderTopWrapper)`
  background-color: var(--white);
  padding: 16px 0 16px 20px;
  border-bottom: 1px solid #f2f2f2;

  @media (min-width: 1300px) {
    padding: 16px 0 16px 30px;
  }
`

const StyledHeaderTitle = styled.h2`
  color: var(--white);
  font-size: 14px;
  font-weight: var(--regular);
  padding: 16px 0 16px 20px;

  @media (min-width: 1300px) {
    padding: 16px 0 16px 30px;
  }
`
