class ComplaintsService {
  async deleteComplaint(id: number) {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/complaints/complaint/${id}/`, {
        method: 'DELETE',
      })
      if (!response.ok) {
        throw Error
      }
    } catch (error) {
      throw new Error('Αποτυχία διαγραφής χρήστη')
    }
  }
}

export default new ComplaintsService()
