import { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import FormSpinner from "../../../components/UI/Spinners/FormSpinner";
import * as Styled from "../../../components/UI/StyledForm";
import { StyledDivider } from "../../../components/UI/StyledDivider";
import { StyledButton } from "../../../components/UI/StyledButton";
import * as yup from "yup";
import { SalesFormTypes } from "../../../interfaces/sales-form.interface";
import { editDateFormat } from "../../../helpers/dateFormat";
import { HiOutlineUserCircle } from "react-icons/hi";
import { IoMdAddCircleOutline } from "react-icons/io";
import { useOffersCtx } from "../../../context/offers-context";
import { SaleTypes } from "../../../interfaces/sales.interface";
import { FaListUl } from "react-icons/fa";
import moment from "moment";

interface Props {
  sale?: SaleTypes;
  onSubmit: (values: SalesFormTypes) => void;
  isFormLoading: boolean;
}

const SalesForm = ({ sale, onSubmit, isFormLoading }: Props) => {
  const {
    selectedUsers,
    selectedProducts,
    showUsersModalHandler,
    showProductsModalHandler,
    onAddProductsCodeHandler,
  } = useOffersCtx();
  const [imagePreview, setImagePreview] = useState<string>(
    sale ? sale.image : "",
  );
  const [prevImage, setPrevImage] = useState<string | File>("");
  const inputFocus = useRef<HTMLInputElement>(null);

  const customersText = selectedUsers.length === 1 ? "Πελάτης" : "Πελάτες";
  const productsText = selectedProducts.length === 1 ? "Προϊόν" : "Προϊόντα";

  const initialValues = {
    title: sale ? sale.title : "",
    description: sale ? sale.description : "",
    image: sale ? sale.image : "",
    discount: sale ? sale.discount.toString() : "",
    startDate: sale ? moment(sale.start_date).format("YYYY-MM-DD") : "",
    expiration_date: sale ? editDateFormat(sale.expiration_date) : "",
    productsCode: "",
  };

  const validationSchema = yup.object({
    title: yup
      .string()
      .trim()
      .required(" ")
      .max(50, "Ο τίτλος πρέπει να είναι μικρότερος από 50 χαρακτήρες"),
    description: yup
      .string()
      .trim()
      .required(" ")
      .max(500, "Η περιγραφή πρέπει να είναι μικρότερη από 500 χαρακτήρες"),
    image: yup.string().required(),
    discount: yup.string().required(),
    startDate: yup.string().required(),
    expiration_date: yup.string().required(),
  });

  const addProductsCodeHandler = () => {
    const productsCode = formik.values.productsCode;
    const separators = [" ", "\n", "\t", ",", "\r\n", "\r"];
    const productsArray = productsCode
      .split(new RegExp(separators.join("|"), "g"))
      ?.filter(Boolean);

    onAddProductsCodeHandler(productsArray);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      URL.revokeObjectURL(imagePreview);
    },
    [imagePreview],
  );

  useEffect(() => inputFocus.current?.focus(), []);

  return (
    <Styled.Form sales onSubmit={formik.handleSubmit}>
      <Styled.InputWrapper sales>
        <Styled.Label
          sales
          htmlFor="title"
          error={formik.touched.title && formik.errors.title ? true : false}
        >
          Τίτλος:
        </Styled.Label>
        <div>
          <Styled.Input
            sales
            id="title"
            type="text"
            placeholder="Προσθήκη Τίτλου"
            error={formik.touched.title && formik.errors.title ? true : false}
            ref={inputFocus}
            {...formik.getFieldProps("title")}
          />
          {formik.touched.title && formik.errors.title && (
            <Styled.ErrorInputMsg error>
              {formik.errors.title}
            </Styled.ErrorInputMsg>
          )}
        </div>
      </Styled.InputWrapper>
      <Styled.InputWrapper sales>
        <Styled.Label
          news
          textArea
          htmlFor="body"
          error={
            formik.touched.description && formik.errors.description
              ? true
              : false
          }
        >
          Περιγραφή:
        </Styled.Label>
        <div>
          <Styled.TextArea
            id="body"
            placeholder="Προσθήκη Περιγραφής"
            rows={3}
            error={
              formik.touched.description && formik.errors.description
                ? true
                : false
            }
            {...formik.getFieldProps("description")}
          />
          {formik.touched.description && formik.errors.description && (
            <Styled.ErrorInputMsg error>
              {formik.errors.description}
            </Styled.ErrorInputMsg>
          )}
        </div>
      </Styled.InputWrapper>
      <Styled.InputWrapper sales>
        <Styled.Label
          sales
          htmlFor="image"
          error={formik.touched.image && formik.errors.image ? true : false}
        >
          Εικόνα:
        </Styled.Label>

        <Styled.FileMsgWrapper>
          <Styled.FileWrapper>
            <Styled.Label file htmlFor="image">
              Αναζήτηση Αρχείου...
            </Styled.Label>
          </Styled.FileWrapper>
          {!imagePreview && (
            <Styled.FileMsg
              error={formik.touched.image && formik.errors.image ? true : false}
            >
              Κανένα Αρχείο.
            </Styled.FileMsg>
          )}
        </Styled.FileMsgWrapper>
        <Styled.InputFile
          type="file"
          id="image"
          name="image"
          accept="image/*"
          onChange={(event) => {
            if (event.target.files !== null) {
              formik.setFieldValue(
                "image",
                event.target.files[0] === undefined
                  ? prevImage
                  : event.target.files[0],
              );

              if (event.target.files[0] !== undefined) {
                setPrevImage(event.target.files[0]);
                setImagePreview(URL.createObjectURL(event.target.files[0]));
              }
            }
          }}
          onBlur={formik.handleBlur}
        />
      </Styled.InputWrapper>
      <Styled.InputWrapper sales>
        <Styled.Label
          sales
          htmlFor="discount"
          error={
            formik.touched.discount && formik.errors.discount ? true : false
          }
        >
          Έκπτωση:
        </Styled.Label>
        <Styled.FileMsgWrapper>
          <Styled.Input
            number
            id="discount"
            type="number"
            min={0}
            max={100}
            placeholder="Πρόσθηκη Έκτπωσης"
            {...formik.getFieldProps("discount")}
            error={
              formik.touched.discount && formik.errors.discount ? true : false
            }
          />
          <Styled.FileMsg
            error={
              formik.touched.discount && formik.errors.discount ? true : false
            }
          >
            Σε ποσοστό %
          </Styled.FileMsg>
        </Styled.FileMsgWrapper>
      </Styled.InputWrapper>
      <Styled.InputWrapper sales>
        <Styled.Label
          sales
          htmlFor="duration"
          error={
            formik.touched.expiration_date && formik.errors.expiration_date
              ? true
              : false
          }
        >
          Διάρκεια:
        </Styled.Label>
        <Styled.DurationWrapper>
          <Styled.Input
            duration
            id="startDate"
            type="date"
            {...formik.getFieldProps("startDate")}
            error={
              formik.touched.startDate && formik.errors.startDate ? true : false
            }
          />
          <Styled.Input
            duration
            id="expiration_date"
            type="date"
            {...formik.getFieldProps("expiration_date")}
            error={
              formik.touched.expiration_date && formik.errors.expiration_date
                ? true
                : false
            }
          />
        </Styled.DurationWrapper>
      </Styled.InputWrapper>
      <Styled.InputWrapper sales>
        <Styled.Label news textArea htmlFor="customers">
          Πελάτες:
        </Styled.Label>
        {selectedUsers.length === 0 && (
          <p style={{ marginRight: "5px" }}>Όλοι οι πελάτες ή προαιρετικά</p>
        )}

        <Styled.FormModalButton onClick={showUsersModalHandler} type="button">
          {selectedUsers.length > 0 ? (
            <>
              <HiOutlineUserCircle color="#00AFEF" size={20} />
              <span>{`${selectedUsers.length} ${customersText}`}</span>
            </>
          ) : (
            <>
              <IoMdAddCircleOutline color="#00AFEF" size={20} />
              <span>Προσθέστε πελάτες</span>
            </>
          )}
        </Styled.FormModalButton>
      </Styled.InputWrapper>
      <Styled.InputWrapper sales>
        <Styled.Label
          news
          textArea
          htmlFor="body"
          error={
            formik.touched.description && formik.errors.description
              ? true
              : false
          }
        >
          Κωδικοί Προϊόντων:
        </Styled.Label>
        <div>
          <Styled.TextArea
            id="body"
            placeholder="Προσθήκη Kωδικών Προϊόντων"
            rows={6}
            {...formik.getFieldProps("productsCode")}
          />
        </div>
        <StyledButton
          publish
          type="button"
          onClick={addProductsCodeHandler}
          disabled={formik.values.productsCode?.trim() === ""}
          style={{
            margin: "auto 0 0 10px",
          }}
        >
          Προσθήκη Kωδικών
        </StyledButton>
      </Styled.InputWrapper>
      <Styled.InputWrapper sales>
        <Styled.Label news textArea htmlFor="customers">
          Προϊόντα:
        </Styled.Label>
        <Styled.FormModalButton
          onClick={showProductsModalHandler}
          type="button"
        >
          {selectedProducts.length > 0 ? (
            <>
              <FaListUl color="#00AFEF" size={20} />
              <span>{`${selectedProducts.length} ${productsText}`}</span>
            </>
          ) : (
            <>
              <IoMdAddCircleOutline color="#00AFEF" size={20} />
              <span>Προσθέστε προϊόντα</span>
            </>
          )}
        </Styled.FormModalButton>
      </Styled.InputWrapper>

      <StyledDivider />
      <Styled.FormBottomWrapper>
        <Styled.ImagePreviewWrapper>
          {imagePreview && <Styled.ImagePreview src={imagePreview} />}
        </Styled.ImagePreviewWrapper>
        <Styled.FormButtonWrapper>
          <StyledButton publish type="submit">
            {isFormLoading ? <FormSpinner /> : "Δημοσίευση"}
          </StyledButton>
        </Styled.FormButtonWrapper>
      </Styled.FormBottomWrapper>
    </Styled.Form>
  );
};

export default SalesForm;
