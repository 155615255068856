import styled, { css } from "styled-components";

interface StyledProps {
  mainContent?: boolean;
  formContent?: boolean;
  dashboard?: boolean;
}

export const StyledContainer = styled.div`
  ${({ mainContent, formContent, dashboard }: StyledProps) => {
    if (dashboard) {
      return css`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 20px;
        gap: 20px;
        @media (min-width: 1350px) {
          padding: 30px;
          height: calc(100% - 100px);
          overflow: unset;
        }
        @media (min-width: 1601px) {
          height: calc(100% - 100px);
        }
      `;
    }
    if (mainContent) {
      return css`
        padding: 20px;
        position: relative;
        min-height: calc(100% - 100px);
        @media (min-width: 1300px) {
          padding: 30px;
        }
      `;
    }

    if (formContent) {
      return css`
        padding: 20px;
        min-height: 85%;
        @media (min-width: 1300px) {
          padding: 30px;
        }
      `;
    }
  }};
`;
