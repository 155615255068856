import { useParams, useHistory } from 'react-router-dom'
import { useContext, useState } from 'react'
import { useSWRConfig } from 'swr'
import { toast } from 'react-toastify'
import ArticleForm from './components/ArticleForm'
import { StyledContainer } from '../../components/UI/StyledContainer'
import { StyledPageSubtitle, StyledPageTitle } from '../../components/UI/StyledPageTitle'
import { authContext } from '../../context/auth-context'
import { NewsFormTypes } from '../../interfaces/news-form.interface'
import { updateResource } from '../../helpers/updateResource'
import { ArticleTypes } from '../../interfaces/news.interface'

interface Props {
  articles: ArticleTypes[]
}

const EditArticle = ({ articles = [] }: Props) => {
  const [isFormLoading, setIsFormLoading] = useState<boolean>(false)
  const params: { id: string } = useParams()
  const { token, destroyToken } = useContext(authContext)
  const { mutate } = useSWRConfig()
  const history = useHistory()
  const article = articles.find(article => article.id === +params.id)

  const onSubmit = async (values: NewsFormTypes) => {
    setIsFormLoading(true)
    const url = `news/article/${params.id}`
    const editArticle = new FormData()
    const method = values.image === article?.image ? 'PATCH' : 'PUT'

    if (
      values.title.trim() === article?.title.trim() &&
      values.body.trim() === article?.body.trim() &&
      values.image === article?.image
    ) {
      toast.warn('There are no changes.')
      setIsFormLoading(false)
      return
    }

    if (method === 'PATCH') {
      if (values.title !== article?.title) editArticle.append('title', values.title)
      if (values.body !== article?.body) editArticle.append('body', values.body)
    }

    if (method === 'PUT') {
      editArticle.append('title', values.title)
      editArticle.append('body', values.body)
      editArticle.append('image', values.image)
    }

    updateResource({
      url,
      method,
      formData: editArticle,
      token,
      setIsFormLoading,
      mutate,
      destroyToken,
      history,
    })
  }

  return (
    <StyledContainer formContent>
      <StyledPageTitle>Επεξεργασία Άρθρου</StyledPageTitle>
      <StyledPageSubtitle>{article?.title}</StyledPageSubtitle>
      <ArticleForm article={article} onSubmit={onSubmit} isFormLoading={isFormLoading} />
    </StyledContainer>
  )
}

export default EditArticle
