import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import useFetch from "../hooks/useFetch";
import { BflowUserTypes } from "../interfaces/bflowUser.interface";
import { SwrDataTypes } from "../interfaces/generics.interface";
import { ProductTypes } from "../interfaces/products.interface";
import { SaleTypes } from "../interfaces/sales.interface";

interface Props {
  children: ReactNode;
  bflowUsers: BflowUserTypes[];
  products: ProductTypes[];
}

interface OffersCtxTypes {
  offers: SwrDataTypes<SaleTypes>;
  bflowUsers: BflowUserTypes[];
  selectedUsers: BflowUserTypes[];
  products: ProductTypes[];
  selectedProducts: ProductTypes[];
  showUsersModal: boolean;
  showProductsModal: boolean;
  showSelectedProductsModal: boolean;
  showUsersModalHandler: () => void;
  showProductsModalHandler: () => void;
  showSelectedProductsModalHandler: () => void;
  onSelectUserHandler: (user: BflowUserTypes) => void;
  onSelectProductHandler: (product: ProductTypes) => void;
  selectAllHandler: () => void;
  onResetHandler: () => void;
  onAddProductsCodeHandler: (codes: ProductTypes["code"][]) => void;
  setSelectedProducts: (products: ProductTypes[]) => void;
  setSelectedUsers: (users: BflowUserTypes[]) => void;
}

const offersCtx = createContext<OffersCtxTypes>({
  offers: {
    data: [],
    error: Error("No data"),
    isLoading: false,
  },
  products: [],
  bflowUsers: [],
  selectedProducts: [],
  selectedUsers: [],
  showUsersModal: false,
  showProductsModal: false,
  showSelectedProductsModal: false,
  showUsersModalHandler: () => {},
  showProductsModalHandler: () => {},
  showSelectedProductsModalHandler: () => {},
  onSelectUserHandler: () => {},
  onSelectProductHandler: () => {},
  onResetHandler: () => {},
  onAddProductsCodeHandler: () => {},
  selectAllHandler: () => {},
  setSelectedProducts: () => {},
  setSelectedUsers: () => {},
});

export const useOffersCtx = (): OffersCtxTypes => useContext(offersCtx);

const OffersProvider = ({
  children,
  bflowUsers = [],
  products = [],
}: Props) => {
  const offers: SwrDataTypes<SaleTypes> = useFetch("offers/offer");
  const [showUsersModal, setShowUsersModal] = useState<boolean>(false);
  const [showProductsModal, setShowProductsModal] = useState<boolean>(false);
  const [showSelectedProductsModal, setShowSelectedProductsModal] =
    useState<boolean>(false);
  const [selectedUsers, setSelectedUsers] = useState<BflowUserTypes[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<ProductTypes[]>([]);

  const showUsersModalHandler = useCallback(
    () => setShowUsersModal((prevState) => !prevState),
    [],
  );
  const showProductsModalHandler = useCallback(
    () => setShowProductsModal((prevState) => !prevState),
    [],
  );
  const showSelectedProductsModalHandler = useCallback(
    () => setShowSelectedProductsModal((prevState) => !prevState),
    [],
  );

  const onSelectUserHandler = useCallback(
    (user: BflowUserTypes) => {
      const existingUser = selectedUsers.some((usr) => usr.id === user.id);
      if (existingUser) {
        setSelectedUsers((prevState) =>
          prevState.filter((usr) => usr.id !== user.id),
        );
        return;
      }
      setSelectedUsers((prevState) => [...prevState, user]);
    },
    [selectedUsers],
  );
  const onSelectProductHandler = useCallback(
    (product: ProductTypes) => {
      const existingProduct = selectedProducts.some(
        (prd) => prd.productid === product.productid,
      );
      if (existingProduct) {
        setSelectedProducts((prevState) =>
          prevState.filter((prd) => prd.productid !== product.productid),
        );
        return;
      }
      setSelectedProducts((prevState) => [...prevState, product]);
    },
    [selectedProducts],
  );

  const onAddProductsCodeHandler = useCallback(
    (codes: ProductTypes["code"][] = []) => {
      // @ts-ignore
      const productsFromCode = [...new Set(codes)]
        .map((code) => products.find((product) => product.code === code))
        .filter(Boolean) as ProductTypes[];
      setSelectedProducts(productsFromCode!);
    },
    [products],
  );

  const selectAllHandler = useCallback(() => {
    if (selectedUsers.length > 0) {
      setSelectedUsers([]);
      return;
    }
    setSelectedUsers(bflowUsers);
  }, [bflowUsers, selectedUsers]);

  const onResetHandler = useCallback(() => {
    setSelectedUsers([]);
    setSelectedProducts([]);
  }, []);

  const ctx = useMemo(
    () => ({
      offers,
      bflowUsers,
      products,
      showUsersModal,
      showProductsModal,
      showSelectedProductsModal,
      selectedUsers,
      selectedProducts,
      showUsersModalHandler,
      showProductsModalHandler,
      showSelectedProductsModalHandler,
      onSelectProductHandler,
      onSelectUserHandler,
      onResetHandler,
      onAddProductsCodeHandler,
      selectAllHandler,
      setSelectedProducts,
      setSelectedUsers,
    }),
    [
      offers,
      bflowUsers,
      products,
      showUsersModal,
      showProductsModal,
      showSelectedProductsModal,
      selectedUsers,
      selectedProducts,
      showUsersModalHandler,
      showProductsModalHandler,
      showSelectedProductsModalHandler,
      onSelectProductHandler,
      onSelectUserHandler,
      onAddProductsCodeHandler,
      onResetHandler,
      selectAllHandler,
      setSelectedProducts,
      setSelectedUsers,
    ],
  );

  return <offersCtx.Provider value={ctx}>{children}</offersCtx.Provider>;
};

export default OffersProvider;
