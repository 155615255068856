import { useState, useEffect, useRef } from 'react'
import { useFormik } from 'formik'
import FormSpinner from '../../../components/UI/Spinners/FormSpinner'
import * as Styled from '../../../components/UI/StyledForm'
import { StyledButton } from '../../../components/UI/StyledButton'
import { StyledDivider } from '../../../components/UI/StyledDivider'
import * as yup from 'yup'
import { NewsFormTypes } from '../../../interfaces/news-form.interface'

interface Props {
  article?: NewsFormTypes
  onSubmit: (values: NewsFormTypes) => void
  isFormLoading: boolean
}

const ArticleForm = ({ article, onSubmit, isFormLoading }: Props) => {
  const [imagePreview, setImagePreview] = useState<string>(article ? article.image : '')
  const [prevImage, setPrevImage] = useState<string | File>('')
  const inputFocus = useRef<HTMLInputElement>(null)

  const initialValues = {
    title: article ? article.title : '',
    body: article ? article.body : '',
    image: article ? article.image : '',
  }

  const validationSchema = yup.object({
    title: yup.string().trim().required(' ').max(50, 'Ο τίτλος πρέπει να είναι μικρότερος από 50 χαρακτήρες'),
    body: yup.string().trim().required(),
    image: yup.string().required(),
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  })

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      URL.revokeObjectURL(imagePreview)
    },
    [imagePreview]
  )

  useEffect(() => inputFocus.current?.focus(), [])

  return (
    <Styled.Form onSubmit={formik.handleSubmit} news>
      <Styled.InputWrapper news>
        <Styled.Label news htmlFor='title' error={formik.touched.title && formik.errors.title ? true : false}>
          Τίτλος:
        </Styled.Label>
        <div>
          <Styled.Input
            news
            id='title'
            type='text'
            placeholder='Προσθήκη Τίτλου'
            error={formik.touched.title && formik.errors.title ? true : false}
            ref={inputFocus}
            {...formik.getFieldProps('title')}
          />
          {formik.touched.title && formik.errors.title && (
            <Styled.ErrorInputMsg error>{formik.errors.title}</Styled.ErrorInputMsg>
          )}
        </div>
      </Styled.InputWrapper>
      <Styled.InputWrapper news>
        <Styled.Label
          news
          textArea
          htmlFor='body'
          error={formik.touched.body && formik.errors.body ? true : false}
        >
          Βασικό Κείμενο:
        </Styled.Label>
        <Styled.TextArea
          id='body'
          placeholder='Προσθήκη Κειμένου'
          rows={8}
          error={formik.touched.body && formik.errors.body ? true : false}
          {...formik.getFieldProps('body')}
        />
      </Styled.InputWrapper>
      <Styled.InputWrapper news>
        <Styled.Label news htmlFor='image' error={formik.touched.image && formik.errors.image ? true : false}>
          Εικόνα:
        </Styled.Label>
        <Styled.FileMsgWrapper>
          <Styled.FileWrapper>
            <Styled.Label file htmlFor='image'>
              Αναζήτηση Αρχείου...
            </Styled.Label>
          </Styled.FileWrapper>
          {!imagePreview && (
            <Styled.FileMsg error={formik.touched.image && formik.errors.image ? true : false}>
              Κανένα Αρχείο.
            </Styled.FileMsg>
          )}
        </Styled.FileMsgWrapper>
        <Styled.InputFile
          type='file'
          id='image'
          name='image'
          accept='image/*'
          onChange={event => {
            if (event.target.files !== null) {
              formik.setFieldValue(
                'image',
                event.target.files[0] === undefined ? prevImage : event.target.files[0]
              )

              if (event.target.files[0] !== undefined) {
                setPrevImage(event.target.files[0])
                setImagePreview(URL.createObjectURL(event.target.files[0]))
              }
            }
          }}
          onBlur={formik.handleBlur}
        />
      </Styled.InputWrapper>
      <StyledDivider />
      <Styled.FormBottomWrapper>
        <Styled.ImagePreviewWrapper>
          {imagePreview && <Styled.ImagePreview src={imagePreview} />}
        </Styled.ImagePreviewWrapper>
        <Styled.FormButtonWrapper>
          {/* <StyledButton draft type='button'>
            Save draft
          </StyledButton> */}
          <StyledButton publish type='submit'>
            {isFormLoading ? <FormSpinner /> : 'Δημοσίευση'}
          </StyledButton>
        </Styled.FormButtonWrapper>
      </Styled.FormBottomWrapper>
    </Styled.Form>
  )
}

export default ArticleForm
