import { useEffect } from 'react'
import styled from 'styled-components'
import LoginForm from './components/LoginForm'
import loginBg from '../../assets/login-bg.svg'

interface StyledProps {
  loginBg?: string
}
const Login = () => {
  useEffect(() => {
    document.title = 'Aegean Fresko | Σύνδεση'
  }, [])

  return (
    <StyledLoginContainer loginBg={loginBg}>
      <LoginForm />
    </StyledLoginContainer>
  )
}

export default Login

const StyledLoginContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ loginBg }: StyledProps) => `url(${loginBg}) no-repeat left bottom`};
  background-size: 90em;

  @media (min-width: 1440px) {
    background-size: 100%;
  }
`
