import ProductsModal from '../../../components/Modal/ProductsModal'
import SelectedProductsModal from '../../../components/Modal/SelectedProductsModal'
import UsersModal from '../../../components/Modal/UsersModal'
import { useOffersCtx } from '../../../context/offers-context'

const SalesModalsContainer = () => {
  const offersCtx = useOffersCtx()
  return (
    <>
      <UsersModal
        bflowUsers={offersCtx.bflowUsers}
        showModal={offersCtx.showUsersModal}
        modalClickHandler={offersCtx.showUsersModalHandler}
        onSelectUserHandler={offersCtx.onSelectUserHandler}
        selectedUsers={offersCtx.selectedUsers}
        selectAllHandler={offersCtx.selectAllHandler}
      />
      <ProductsModal />
      <SelectedProductsModal />
    </>
  )
}

export default SalesModalsContainer
