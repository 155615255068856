import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { BrowserRouter as Router } from 'react-router-dom'
import GlobalStyles from './global-styles'
import AuthContextProvider from './context/auth-context'

ReactDOM.render(
  <React.StrictMode>
    <AuthContextProvider>
      <Router>
        <GlobalStyles />
        <App />
      </Router>
    </AuthContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
