import moment from 'moment'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { StyledContainer } from '../../components/UI/StyledContainer'
import { StyledPageTitle, StyledPageTitleWrapper } from '../../components/UI/StyledPageTitle'
import { ComplaintTypes } from '../../interfaces/complaints.interface'

interface Props {
  complaints: ComplaintTypes[]
}
const ViewComplaint = ({ complaints = [] }: Props) => {
  const params = useParams<{ id: string }>()
  const complaint = complaints.find(complaint => complaint.id === +params.id)

  useEffect(() => {
    document.title = 'Aegean Fresko | Προβολή Παραπόνου'
  }, [])

  return (
    <StyledContainer mainContent>
      <StyledPageTitleWrapper>
        <StyledPageTitle>{`Παράπονο ${complaint?.id ?? ''} - ${moment(complaint?.date_created).format(
          'DD/MM/YYYY'
        )}`}</StyledPageTitle>
      </StyledPageTitleWrapper>
      <StyledComplaintWrapper>
        <StyledComplaintTextWrapper>
          <StyledComplainText>Τίτλος:</StyledComplainText>
          <StyledComplaintTitle>{complaint?.title}</StyledComplaintTitle>
        </StyledComplaintTextWrapper>

        {complaint?.customer_name && (
          <StyledComplaintTextWrapper>
            <StyledComplainText>Πελάτης:</StyledComplainText>
            <StyledComplaintSubTitle>{complaint?.customer_name}</StyledComplaintSubTitle>
          </StyledComplaintTextWrapper>
        )}
        <StyledComplaintTextWrapper>
          <StyledComplainText>ΑΦΜ:</StyledComplainText>
          <StyledComplaintSubTitle>{complaint?.customer_vat}</StyledComplaintSubTitle>
        </StyledComplaintTextWrapper>
        {complaint?.customer_telephone && (
          <StyledComplaintTextWrapper>
            <StyledComplainText>Τηλέφωνο:</StyledComplainText>
            <StyledComplaintSubTitle>{complaint?.customer_telephone}</StyledComplaintSubTitle>
          </StyledComplaintTextWrapper>
        )}
        {complaint?.product_name && (
          <StyledComplaintTextWrapper>
            <StyledComplainText>Προϊόν</StyledComplainText>
            <StyledComplaintSubTitle>{complaint?.product_name}</StyledComplaintSubTitle>
          </StyledComplaintTextWrapper>
        )}
        <StyledComplaintTextWrapper>
          <StyledComplainText style={{ alignSelf: 'flex-start' }}>Περιγραφή:</StyledComplainText>
          <StyledComplaintDescription>{complaint?.description}</StyledComplaintDescription>
        </StyledComplaintTextWrapper>

        <StyledImageContainer>
          {complaint?.complaint_images.map(image => (
            <a key={image.id} href={image.image} target='_blank' rel='noreferrer'>
              <img src={image.image} alt='' />
            </a>
          ))}
        </StyledImageContainer>
      </StyledComplaintWrapper>
    </StyledContainer>
  )
}

export default ViewComplaint

const StyledComplaintWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`

const StyledComplaintTextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

const StyledComplaintTitle = styled.h2`
  display: flex;
  flex-direction: column;
`

const StyledComplaintSubTitle = styled.h3``

const StyledComplaintDescription = styled.p`
  max-height: 300px;
  overflow-y: auto;
`

const StyledImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;

  img {
    width: 110px;
    height: 90px;
    border-radius: 15px;
    object-fit: cover;
  }
`

const StyledComplainText = styled.p`
  font-weight: 500;
`
