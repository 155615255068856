import { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useSWRConfig } from 'swr'
import MessageForm from './components/MessageForm'
import UsersModal from '../../components/Modal/UsersModal'
import { StyledContainer } from '../../components/UI/StyledContainer'
import { StyledPageTitle } from '../../components/UI/StyledPageTitle'
import { authContext } from '../../context/auth-context'
import { BflowUserTypes } from '../../interfaces/bflowUser.interface'
import { MessageFormTypes } from '../../interfaces/messages.interface'
import messagesService from '../../services/messagesService'

interface Props {
  bflowUsers: BflowUserTypes[]
}

const AddMessage = ({ bflowUsers = [] }: Props) => {
  const { token } = useContext(authContext)
  const [isFormLoading, setIsFormLoading] = useState<boolean>(false)
  const [showUsersModal, setShowUsersModal] = useState<boolean>(false)
  const [selectedUsers, setSelectedUsers] = useState<BflowUserTypes[]>([])

  const history = useHistory()
  const { mutate } = useSWRConfig()

  const onSubmit = (values: MessageFormTypes) => {
    if (selectedUsers.length === 0) {
      toast.warn('Επιλέξτε παραλήπτες')
      return
    }
    setIsFormLoading(true)
    messagesService
      .createMessage({
        values,
        token,
        recipients: selectedUsers,
      })
      .then(() => {
        toast.success('Το μήνυμα απεστάλη επιτυχώς')
        mutate('privateMessages/pm')
        setIsFormLoading(false)
        history.push('/messages')
      })
      .catch(error => {
        setIsFormLoading(false)
        toast.error(error.message)
      })
  }

  const showUsersModalHandler = () => setShowUsersModal(prevState => !prevState)

  const onSelectUserHandler = (user: BflowUserTypes) => {
    const existingUser = selectedUsers.some(usr => usr.id === user.id)
    if (existingUser) {
      setSelectedUsers(prevState => prevState.filter(usr => usr.id !== user.id))
      return
    }
    setSelectedUsers(prevState => [...prevState, user])
  }

  const selectAllHandler = () => {
    if (selectedUsers.length > 0) {
      setSelectedUsers([])
      return
    }
    setSelectedUsers(bflowUsers)
  }

  return (
    <>
      <StyledContainer formContent>
        <StyledPageTitle>Νέο Μήνυμα</StyledPageTitle>
        <MessageForm
          onSubmit={onSubmit}
          isFormLoading={isFormLoading}
          showUsersModalHandler={showUsersModalHandler}
          selectedUsers={selectedUsers}
        />
      </StyledContainer>
      <UsersModal
        bflowUsers={bflowUsers}
        showModal={showUsersModal}
        modalClickHandler={showUsersModalHandler}
        onSelectUserHandler={onSelectUserHandler}
        selectedUsers={selectedUsers}
        selectAllHandler={selectAllHandler}
      />
    </>
  )
}

export default AddMessage
