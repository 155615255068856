import { UpdateImageTypes } from '../interfaces/products.interface'

class ProductService {
  async updateProductImage({ id, image, token }: UpdateImageTypes) {
    const imageData = new FormData()
    imageData.append('product_photo', image)
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_HOST}/api/products/product/${id}/patch-photo/`,
        {
          method: 'PATCH',
          body: imageData,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      if (!response.ok) {
        throw Error
      }
    } catch (error) {
      throw new Error('Αποτυχία ενημέρωσης φωτογραφίας')
    }
  }
}
export default new ProductService()
