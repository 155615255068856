import styled from 'styled-components'
import { FiChevronRight } from 'react-icons/fi'

interface Props {
  path: string
}

const HeaderPaths = ({ path }: Props) => {
  let displayPath: string | JSX.Element

  const editResource = path.startsWith('/news/edit-article')
    ? '/news/edit-article'
    : path.startsWith('/sales/edit-sale')
    ? '/sales/edit-sale'
    : path.startsWith('/products/update-product-image')
    ? '/products/update-product-image'
    : path.startsWith('/erp-users/edit-erp-user')
    ? '/erp-users/edit-erp-user'
    : path.startsWith('/complaints/complaint')
    ? '/complaints/complaint'
    : ''

  switch (true) {
    case path === '/':
      displayPath = 'Πίνακας Ελέγχου'
      break
    case path === '/messages':
      displayPath = 'Μηνύματα'
      break
    case path === '/news':
      displayPath = 'Νέα'
      break
    case path === '/sales':
      displayPath = 'Προσφορές'
      break
    case path === '/erp-users':
      displayPath = 'Χρήστες ERP'
      break
    case path === '/bflow-users':
      displayPath = 'Χρήστες BFLOW'
      break
    case path === '/products':
      displayPath = 'Προϊόντα'
      break
    case path === '/complaints':
      displayPath = 'Παράπονα'
      break
    case path === '/news/add-article':
      displayPath = (
        <span>
          Νέα <FiChevronRight className='chevron-icon' /> <span className='regular'>Προσθήκη Άρθρου</span>
        </span>
      )
      break
    case path === '/sales/add-sale':
      displayPath = (
        <span>
          Προσφορές <FiChevronRight className='chevron-icon' />{' '}
          <span className='regular'>Προσθήκη Προσφοράς</span>
        </span>
      )
      break
    case path === '/messages/add-message':
      displayPath = (
        <span>
          Μηνύματα <FiChevronRight className='chevron-icon' />{' '}
          <span className='regular'>Προσθήκη Μηνύματος</span>
        </span>
      )
      break
    case editResource === '/news/edit-article':
      displayPath = (
        <span>
          Νέα <FiChevronRight className='chevron-icon' /> <span className='regular'>Επεξεργασία Άρθρου</span>
        </span>
      )
      break
    case editResource === '/sales/edit-sale':
      displayPath = (
        <span>
          Προσφορές <FiChevronRight className='chevron-icon' />{' '}
          <span className='regular'>Επεξεργασία Προσφοράς</span>
        </span>
      )
      break
    case editResource === '/erp-users/edit-erp-user':
      displayPath = (
        <span>
          Χρήστες <FiChevronRight className='chevron-icon' />{' '}
          <span className='regular'>Επεξεργασία Χρήστη ERP</span>
        </span>
      )
      break
    case editResource === '/complaints/complaint':
      displayPath = (
        <span>
          Παράπονα <FiChevronRight className='chevron-icon' />{' '}
          <span className='regular'>Προβολή Παραπόνου</span>
        </span>
      )
      break
    case editResource === '/products/update-product-image':
      displayPath = (
        <span>
          Προϊόντα <FiChevronRight className='chevron-icon' />{' '}
          <span className='regular'>Επεξεργασία Προϊόντος</span>
        </span>
      )
      break
    default:
      displayPath = ''
      break
  }
  return <StyledPaths>{displayPath}</StyledPaths>
}

export default HeaderPaths

const StyledPaths = styled.p`
  font-size: 1rem;
  font-weight: var(--regular);
  text-transform: capitalize;

  .chevron-icon {
    transform: scale(1.3) translateY(2.3px);
    margin: 0 6px;
  }

  .regular {
    font-weight: var(--regular);
  }
`
