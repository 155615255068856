import React from 'react'
import { Route } from 'react-router-dom'
import { COMPLAINTS } from '../../constant/routes'
import useFetch from '../../hooks/useFetch'
import { ComplaintTypes } from '../../interfaces/complaints.interface'
import { SwrDataTypes } from '../../interfaces/generics.interface'
import Complaints from '../../pages/Complaints/Complaints'
import ViewComplaint from '../../pages/Complaints/ViewComplaint'

const ComplaintsRoutes = () => {
  const {
    data: complaints,
    error: complaintsError,
    isLoading: isComplaintsLoading,
  }: SwrDataTypes<ComplaintTypes> = useFetch('complaints/complaint')

  return (
    <>
      <Route path={COMPLAINTS.main} exact>
        <Complaints
          complaints={complaints}
          isLoading={isComplaintsLoading}
          complaintsError={complaintsError}
        />
      </Route>
      <Route path={`${COMPLAINTS.view}/:id`} exact>
        <ViewComplaint complaints={complaints} />
      </Route>
    </>
  )
}

export default ComplaintsRoutes
