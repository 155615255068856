import { FaListUl } from 'react-icons/fa'
import { useOffersCtx } from '../../context/offers-context'
import ProductsTable from '../Table/ProductsTable'
import { FormModalButton } from '../UI/StyledForm'
import { StyledProductsModal } from '../UI/StyledModal'
import Modal from './Modal'
import ModalTitle from './ModalTitle'

const ProductsModal = () => {
  const offersCtx = useOffersCtx()
  const productsText = offersCtx.selectedProducts.length === 1 ? 'Προϊόν' : 'Προϊόντα'

  return (
    <Modal showModal={offersCtx.showProductsModal} onClickBackdrop={offersCtx.showProductsModalHandler}>
      <StyledProductsModal showModal={offersCtx.showProductsModal}>
        <ModalTitle title='Επιλογή Προϊόντων' modalClickHandler={offersCtx.showProductsModalHandler}>
          <FormModalButton onClick={offersCtx.showSelectedProductsModalHandler} type='button'>
            {offersCtx.selectedProducts.length > 0 && (
              <>
                <FaListUl color='#00AFEF' size={20} />
                <span>{`${offersCtx.selectedProducts.length} ${productsText}`}</span>
              </>
            )}
          </FormModalButton>
        </ModalTitle>

        <ProductsTable products={offersCtx.products} />
      </StyledProductsModal>
    </Modal>
  )
}

export default ProductsModal
