import { useContext, useEffect, useRef, useState } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import styled from 'styled-components'
import * as yup from 'yup'
import FormSpinner from '../../../components/UI/Spinners/FormSpinner'
import { StyledButton } from '../../../components/UI/StyledButton'
import * as Styled from '../../../components/UI/StyledForm'
import { DASHBOARD } from '../../../constant/routes'
import { authContext } from '../../../context/auth-context'
import loginLogo from '../../../assets/login-logo.png'
import jwt_decode from 'jwt-decode'
import { DecodedTokenTypes } from '../../../interfaces/decodedToken.interface'

const LoginForm = () => {
  const [isFormLoading, setIsFormLoading] = useState<boolean>(false)
  const [newError, setNewError] = useState<string | null>(null)
  const history = useHistory()
  const { token, saveToken } = useContext(authContext)

  const inputFocus = useRef<HTMLInputElement>(null)
  useEffect(() => inputFocus.current?.focus(), [])

  const initialValues = {
    username: '',
    password: '',
  }

  const validationSchema = yup.object({
    username: yup.string().required(),
    password: yup.string().required(),
  })

  const onSubmit = async (values: {}) => {
    setNewError(null)
    setIsFormLoading(true)
    try {
      const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/login/`, {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
          'Content-Type': 'application/json',
        },
      })
      const jwt = await response.json()
      const decodedJWT: DecodedTokenTypes = jwt_decode(jwt.data.access)
      if (!response.ok || decodedJWT?.user_type === 'CUSTOMER') {
        throw new Error('Λανθασμένο όνομα χρήστη ή κωδικός πρόσβασης')
      }

      if (response.ok) {
        setNewError(null)
        saveToken(jwt.data.access)
        setIsFormLoading(false)
        history.replace('/')
      }
    } catch (err) {
      if (err instanceof Error) {
        setNewError(err.message)
        setIsFormLoading(false)
      }
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  })

  if (token) {
    return <Redirect to={DASHBOARD} />
  }

  return (
    <StyledFormWrapper>
      <StyledLogoWrapper>
        <img src={loginLogo} alt='' />
      </StyledLogoWrapper>
      <Styled.Form login onSubmit={formik.handleSubmit}>
        {newError && <Styled.ErrorInputMsg loginError>{newError}</Styled.ErrorInputMsg>}
        <Styled.InputWrapper>
          <Styled.Input
            login
            id='vat'
            type='text'
            error={formik.touched.username && formik.errors.username ? true : false}
            placeholder='Είσοδος'
            ref={inputFocus}
            {...formik.getFieldProps('username')}
          />
        </Styled.InputWrapper>
        <Styled.InputWrapper>
          <Styled.Input
            login
            id='password'
            type='password'
            placeholder='Κωδικός'
            error={formik.touched.password && formik.errors.password ? true : false}
            {...formik.getFieldProps('password')}
          />
        </Styled.InputWrapper>
        <StyledButton login type='submit'>
          {isFormLoading ? <FormSpinner /> : 'Είσοδος'}
        </StyledButton>
      </Styled.Form>
    </StyledFormWrapper>
  )
}

export default LoginForm

export const StyledFormWrapper = styled.div`
  max-width: 405px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledLogoWrapper = styled.div`
  width: 309px;
  margin-bottom: 5rem;

  img {
    width: 100%;
    height: 100%;
  }
`
