import { BflowUserTypes } from '../../interfaces/bflowUser.interface'
import { StyledUsersModal } from '../UI/StyledModal'
import Modal from './Modal'
import styled from 'styled-components'
import { IoMdAddCircleOutline, IoMdRemoveCircleOutline } from 'react-icons/io'
import { StyledSearchInput } from '../Table/Search/Search'
import { useEffect, useState } from 'react'
import ModalTitle from './ModalTitle'

interface Props {
  showModal: boolean
  bflowUsers: BflowUserTypes[]
  selectedUsers: BflowUserTypes[]
  modalClickHandler: () => void
  selectAllHandler: () => void
  onSelectUserHandler: (user: BflowUserTypes) => void
}

const UsersModal = ({
  bflowUsers = [],
  selectedUsers = [],
  showModal,
  modalClickHandler,
  selectAllHandler,
  onSelectUserHandler,
}: Props) => {
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [searchedUsers, setSearchedUsers] = useState<BflowUserTypes[]>(bflowUsers)

  const searchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
    const filteredUsers = bflowUsers.filter(user =>
      user.customer_name.toLowerCase().includes(e.target.value.toLowerCase())
    )
    setSearchedUsers(filteredUsers)
  }

  // Initialize state for searched users
  useEffect(() => {
    setSearchedUsers(bflowUsers)
  }, [bflowUsers])

  return (
    <Modal showModal={showModal} onClickBackdrop={modalClickHandler}>
      <StyledUsersModal showModal={showModal}>
        <ModalTitle title='Επιλογή Χρηστών' modalClickHandler={modalClickHandler} />
        <StyledSearchUsersWrapper>
          <StyledSearchInput placeholder='Αναζήτηση' value={searchTerm} onChange={searchHandler} />
        </StyledSearchUsersWrapper>
        <StyledBodyWrapper>
          {searchTerm.trim().length === 0 && (
            <button onClick={selectAllHandler} className='select-all' type='button'>
              {selectedUsers.length > 0 ? 'Αφαίρεση Όλων' : "Επιλογή 'Ολων"}
            </button>
          )}
          {searchedUsers.map(user => {
            const existingUser = selectedUsers.some(usr => usr.id === user.id)
            return (
              <div
                style={{
                  backgroundColor: existingUser ? 'rgba(0,175,239,0.3)' : 'transparent',
                  borderRadius: existingUser ? '5px' : '0',
                }}
                key={user.id}
                className='user-wrapper'
                onClick={() => onSelectUserHandler(user)}
              >
                <p>{user.customer_name || '-'}</p>
                {existingUser ? (
                  <IoMdRemoveCircleOutline className='svgRemove' size={24} />
                ) : (
                  <IoMdAddCircleOutline className='svgAdd' size={24} />
                )}
              </div>
            )
          })}
        </StyledBodyWrapper>
      </StyledUsersModal>
    </Modal>
  )
}

export default UsersModal

const StyledBodyWrapper = styled.div`
  padding: 10px 0;
  overflow-y: auto;
  height: calc(100% - 90px);

  .select-all {
    all: unset;
    margin-bottom: 10px;
    cursor: pointer;
    transition: all 200ms;
    font-size: 0.875rem;
    &:hover {
      color: var(--primary-color);
    }
  }

  .user-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--border-gray);
    padding: 0.3rem 0;
    cursor: pointer;

    &:last-child {
      border-bottom: none;
    }
  }

  .svgAdd,
  .svgRemove {
    transition: all 200ms;
    cursor: pointer;
    padding-right: 0.3rem;
  }
  .svgAdd:hover {
    color: var(--primary-color);
  }
  .svgRemove:hover {
    color: var(--delete);
  }
`

const StyledSearchUsersWrapper = styled.div`
  width: 100%;
  padding: 0.5rem 0;
  margin: 10px 0;
  border-bottom: 1px solid var(--border-gray);
`
