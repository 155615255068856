export const DASHBOARD = "/";
export const LOGIN = "/login";
export const REDIRECT = "/redirect";

export const PRODUCTS = {
  main: "/products",
  edit: "/products/update-product-image",
};

export const MESSAGES = {
  main: "/messages",
  add: "/messages/add-message",
};

export const NEWS = {
  main: "/news",
  add: "/news/add-article",
  edit: "/news/edit-article",
};

export const SALES = {
  main: "/sales",
  add: "/sales/add-sale",
  edit: "/sales/edit-sale",
  pdf: "/sales/offer-pdf",
};

export const ERPUSERS = {
  main: "/erp-users",
  edit: "/erp-users/edit-erp-user",
};

export const BFLOWUSERS = {
  main: "/bflow-users",
};

export const COMPLAINTS = {
  main: "/complaints",
  view: "/complaints/complaint",
};
