import React from 'react'
import { Route } from 'react-router-dom'
import { PRODUCTS } from '../../constant/routes'
import { ProductTypes } from '../../interfaces/products.interface'
import EditProduct from '../../pages/Products/EditProduct'
import Products from '../../pages/Products/Products'

interface Props {
  products: ProductTypes[]
  isLoading: boolean
  productsError: Error
}

const ProductsRoutes = ({ products = [], isLoading, productsError }: Props) => {
  return (
    <>
      <Route path={PRODUCTS.main} exact>
        <Products products={products} isLoading={isLoading} productsError={productsError} />
      </Route>
      <Route path={`${PRODUCTS.edit}/:id`} exact>
        <EditProduct products={products} />
      </Route>
    </>
  )
}

export default ProductsRoutes
