import { useEffect } from 'react'
import styled from 'styled-components'
import Spinner from '../../components/UI/Spinners/Spinner'

const RedirectToMobileStore = () => {
  const appStoreLink = 'https://apps.apple.com/gr/app/aegean-fresko-b2b/id1635097754'
  const playStoreLink = 'https://play.google.com/store/apps/details?id=com.aegeanfresko.b2b'

  const isMobile = navigator.userAgent.match(/Android|iPhone|Macintosh|iPad/i)

  const mobileStoreLink =
    isMobile?.includes('iPhone') || isMobile?.includes('iPad') || isMobile?.includes('Macintosh')
      ? appStoreLink
      : playStoreLink

  useEffect(() => {
    if (isMobile) {
      window.location.href = mobileStoreLink
    } else {
      window.location.href = playStoreLink
    }
  }, [isMobile, mobileStoreLink])

  return (
    <StyledContainer>
      <div>
        <Spinner />
      </div>
      <p className='redirect-text'>
        Εάν δεν έχετε μεταφερθεί αυτόματα στο κατάστημα της εφαρμογής σε 3 δευτερόλεπτα
      </p>
      <a href={mobileStoreLink} className='redirect-link'>
        Πατήστε εδώ
      </a>
    </StyledContainer>
  )
}

export default RedirectToMobileStore

const StyledContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  padding: 20px;

  .redirect-text {
    max-width: 400px;
    text-align: center;
  }

  .redirect-link {
    all: unset;
    background-color: var(--primary-color);
    color: var(--white);
    border-radius: 8px;
    padding: 10px 20px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
      opacity: 0.9;
    }
  }
`
