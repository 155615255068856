import { Route } from 'react-router-dom'
import { MESSAGES } from '../../constant/routes'
import { BflowUserTypes } from '../../interfaces/bflowUser.interface'
import AddMessage from '../../pages/Messages/AddMessage'
import Messages from '../../pages/Messages/Messages'

interface Props {
  bflowUsers: BflowUserTypes[]
}

const MessagesRoutes = ({ bflowUsers = [] }: Props) => {
  return (
    <>
      <Route path={MESSAGES.main} exact>
        <Messages />
      </Route>
      <Route path={MESSAGES.add} exact>
        <AddMessage bflowUsers={bflowUsers} />
      </Route>
    </>
  )
}

export default MessagesRoutes
