import { useState } from 'react'
import { useAsyncDebounce } from 'react-table'
import styled from 'styled-components'
import { FiSearch } from 'react-icons/fi'
import InputSpinner from '../../UI/Spinners/InputSpinner'

interface Props {
  filter: string
  setFilter: (value: string) => void
  placeholder?: string
}

const Search = ({ filter, setFilter, placeholder }: Props) => {
  const [value, setValue] = useState<string>(filter)
  const [isSearching, setIsSearching] = useState<boolean>(false)

  const handleChange = useAsyncDebounce(value => {
    setIsSearching(false)
    return setFilter(value.trim() || undefined)
  }, 500)

  return (
    <StyledSearchWrapper>
      <StyledSearchInput
        type='text'
        placeholder={placeholder}
        value={value || ''}
        onChange={event => {
          setValue(event.target.value)
          setIsSearching(true)
          handleChange(event.target.value)
        }}
      />
      {isSearching ? <InputSpinner /> : <FiSearch cursor='pointer' />}
    </StyledSearchWrapper>
  )
}

export default Search

export const StyledSearchWrapper = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid var(--border-light-gray);
  margin: 0 0 30px auto;
  padding: 14px 20px;
  transition: all 200ms ease-out;
  width: 332px;
  border-radius: 15px;
  &:hover {
    border: 1px solid var(--primary-color);
  }
`

export const StyledSearchInput = styled.input`
  border: none;
  font-family: inherit;
  font-size: 1rem;
  outline: none;
  width: 100%;

  &::placeholder {
    color: var(--border-light-gray);
    font-size: 0.875rem;
  }
`
