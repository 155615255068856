import styled from 'styled-components'
import dashboardLogo from '../../assets/dashboard-logo.png'

const Logo = () => {
  return (
    <StyledLogo>
      <img src={dashboardLogo} alt='' />
    </StyledLogo>
  )
}

export default Logo

export const StyledLogo = styled.div`
  width: 204px;
  img {
    width: 100%;
    height: 100%;
  }
`
