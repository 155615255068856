import { MdDelete } from 'react-icons/md'
import styled from 'styled-components'
import { useOffersCtx } from '../../context/offers-context'
import { StyledBackdrop } from '../UI/StyledBackdrop'
import { StyledSelectedProductsModal } from '../UI/StyledModal'
import Modal from './Modal'
import ModalTitle from './ModalTitle'

const SelectedProductsModal = () => {
  const offersCtx = useOffersCtx()
  return (
    <>
      <StyledBackdrop
        showModal={offersCtx.showSelectedProductsModal}
        onClick={offersCtx.showSelectedProductsModalHandler}
        style={{ zIndex: 95 }}
      />
      <Modal
        showModal={offersCtx.showSelectedProductsModal}
        onClickBackdrop={offersCtx.showSelectedProductsModalHandler}
      >
        <StyledSelectedProductsModal showModal={offersCtx.showSelectedProductsModal}>
          <ModalTitle
            title='Επιλεγμένα Προϊόντα'
            modalClickHandler={offersCtx.showSelectedProductsModalHandler}
          />
          <StyledProductsWrapper>
            {offersCtx.selectedProducts.map(product => (
              <StyledSelectedProduct key={product.productid}>
                {product.name}
                <MdDelete
                  onClick={offersCtx.onSelectProductHandler.bind(null, product)}
                  className='delete-icon'
                />
              </StyledSelectedProduct>
            ))}
          </StyledProductsWrapper>
        </StyledSelectedProductsModal>
      </Modal>
    </>
  )
}

export default SelectedProductsModal

const StyledProductsWrapper = styled.div`
  margin-top: 1rem;
  height: calc(100% - 50px);
  overflow-y: auto;
`

const StyledSelectedProduct = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 2px);
  padding: 0.5rem 0.1rem 0.5rem 0;
  border-bottom: 1px solid var(--dark-gray);

  .delete-icon {
    color: var(--delete);
    cursor: pointer;
    transform: scale(1.2);
  }
`
