import React from "react";
import { Page, View, StyleSheet, Image } from "@react-pdf/renderer";
import { ProductTypes } from "../../../../interfaces/products.interface";
import PdfProductsContainer from "./PdfProductsContainer";
import PdfProductCard from "./PdfProductCard";
import bgImg from "../../../../assets/first-pdf-bg.png";

const styles = StyleSheet.create({
  page: { fontFamily: "Isotext Pro" },
  section: {
    flexGrow: 1,
  },
  backgroundImage: {
    position: "absolute",
    width: "100%",
    height: "617",
    bottom: 0,
    left: 0,
    right: 0,
  },
  row: {
    flexDirection: "row",
  },
  cell: {
    width: "33.33%",
    padding: 5,
  },
});

interface Props {
  products: ProductTypes[];
  discount: number;
}

const ProductsPdfPage = ({ products, discount }: Props) => {
  return (
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Image src={bgImg} style={styles.backgroundImage} />

        <PdfProductsContainer>
          {products.map((product, index) =>
            index % 3 === 0 ? (
              <View style={styles.row} key={index}>
                {products[index] && (
                  <View style={styles.cell}>
                    <PdfProductCard
                      product={products[index]}
                      discount={discount}
                    />
                  </View>
                )}
                {products[index + 1] && (
                  <View style={styles.cell}>
                    <PdfProductCard
                      product={products[index + 1]}
                      discount={discount}
                    />
                  </View>
                )}
                {products[index + 2] && (
                  <View style={styles.cell}>
                    <PdfProductCard
                      product={products[index + 2]}
                      discount={discount}
                    />
                  </View>
                )}
              </View>
            ) : null,
          )}
        </PdfProductsContainer>
      </View>
    </Page>
  );
};

export default ProductsPdfPage;
