import { useEffect } from 'react'
import ErpUsersTable from '../../components/Table/ErpUsersTable'
import Spinner from '../../components/UI/Spinners/Spinner'
import { StyledContainer } from '../../components/UI/StyledContainer'
import {
  StyledPageTitle,
  StyledPageTitleWrapper,
  StyledPageUserSubtitle,
} from '../../components/UI/StyledPageTitle'
import useFetch from '../../hooks/useFetch'
import { ErpUsersDataTypes } from '../../interfaces/erpUser.interface'

const ErpUsers = () => {
  useEffect(() => {
    document.title = 'Aegean Fresko | ERP Users'
  }, [])

  const {
    data: erpUsers,
    error: erpUsersError,
    isLoading: isErpUsersLoading,
  }: ErpUsersDataTypes = useFetch('auth/customer/get-erps')

  return (
    <StyledContainer mainContent>
      <StyledPageTitleWrapper>
        <StyledPageTitle>Χρήστες ERP</StyledPageTitle>
      </StyledPageTitleWrapper>
      <StyledPageUserSubtitle>Εδώ εμφανίζονται όλοι οι χρήστες του ERP</StyledPageUserSubtitle>
      {isErpUsersLoading ? <Spinner /> : <ErpUsersTable erpUsers={erpUsers} erpUsersError={erpUsersError} />}
    </StyledContainer>
  )
}

export default ErpUsers
