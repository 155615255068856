import { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useSWRConfig } from 'swr'
import { toast } from 'react-toastify'

import { StyledContainer } from '../../components/UI/StyledContainer'
import { StyledPageTitle } from '../../components/UI/StyledPageTitle'
import ErpUserForm from './components/ErpUserForm'

import { ErpUserFormTypes } from '../../interfaces/erpUser.interface'
import usersService from '../../services/usersService'
import { ERPUSERS } from '../../constant/routes'

type Params = {
  id: string
}

const EditUser = () => {
  const [isFormLoading, setIsFormLoading] = useState<boolean>(false)
  const params: Params = useParams()
  const history = useHistory()
  const { mutate } = useSWRConfig()

  const onSubmit = (values: ErpUserFormTypes) => {
    setIsFormLoading(true)
    usersService
      .registerUser(values)
      .then(() => {
        toast.success('Ο χρήστης ενημερώθηκε με επιτυχία')
        history.push(ERPUSERS.main)
        mutate('auth/customer/get-erps')
        setIsFormLoading(false)
      })
      .catch(error => {
        toast.error(error.message)
        setIsFormLoading(false)
      })
  }

  return (
    <StyledContainer formContent>
      <StyledPageTitle>Επεξεργασία Χρήστη</StyledPageTitle>
      <ErpUserForm erpId={params.id} isFormLoading={isFormLoading} onSubmit={onSubmit} />
    </StyledContainer>
  )
}

export default EditUser
