import { useContext, useState } from 'react'
import { useSWRConfig } from 'swr'
import { useHistory } from 'react-router-dom'
import { StyledContainer } from '../../components/UI/StyledContainer'
import { StyledPageTitle } from '../../components/UI/StyledPageTitle'
import SalesForm from './components/SalesForm'
import { SalesFormTypes } from '../../interfaces/sales-form.interface'
import { authContext } from '../../context/auth-context'
import { createResource } from '../../helpers/createResource'
import SalesModalsContainer from './components/SalesModalsContainer'
import { useOffersCtx } from '../../context/offers-context'
import { toast } from 'react-toastify'

const AddNewSale = () => {
  const [isFormLoading, setIsFormLoading] = useState<boolean>(false)
  const { token, destroyToken } = useContext(authContext)
  const { mutate } = useSWRConfig()
  const history = useHistory()
  const offersCtx = useOffersCtx()

  const onSubmit = async (values: SalesFormTypes) => {
    if (offersCtx.selectedProducts.length === 0) {
      toast.warn('Επιλέξτε προϊόντα για την προσφορά')
      return
    }

    setIsFormLoading(true)
    const url = 'offers/offer'
    const newSale = new FormData()

    newSale.append('title', values.title)
    newSale.append('description', values.description)
    newSale.append('image', values.image)
    newSale.append('discount', values.discount)
    newSale.append('start_date', values.startDate)
    newSale.append('expiration_date', values.expiration_date)
    for (const product of offersCtx.selectedProducts) newSale.append('products', product.productid.toString())
    if (offersCtx.selectedUsers.length > 0) {
      for (const user of offersCtx.selectedUsers) newSale.append('customers', user.id.toString())
    }

    createResource({
      url,
      token,
      formData: newSale,
      setIsFormLoading,
      mutate,
      destroyToken,
      history,
      onResetHandler: offersCtx.onResetHandler,
    })
  }

  return (
    <>
      <StyledContainer formContent>
        <StyledPageTitle>Προσθήκη Προσφοράς</StyledPageTitle>
        <SalesForm onSubmit={onSubmit} isFormLoading={isFormLoading} />
      </StyledContainer>
      <SalesModalsContainer />
    </>
  )
}

export default AddNewSale
