import { useContext } from 'react'
import useSWR from 'swr'
import { toast } from 'react-toastify'
import { authContext } from '../context/auth-context'
import { dateFormat } from '../helpers/dateFormat'
import { sortByDatetime } from '../helpers/sortByDatetime'
import { BflowUserTypes } from '../interfaces/bflowUser.interface'

const useFetch = (url: string) => {
  const { token, destroyToken } = useContext(authContext)

  const fetcher = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/${url}/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    const results = await response.json()

    if (response.status === 401) {
      toast.error('Η σύνδεση σας έχει λήξει. Παρακαλώ συνδεθείτε ξανά.')
      destroyToken()
    }

    if (url === 'news/article') {
      if (!response.ok) throw new Error('Αποτυχία ανάκτησης άρθρων.')

      sortByDatetime(results, 'published')

      const data = results.map((result: { published: string }) => ({
        ...result,
        published: dateFormat(result.published),
        status: 'Δημοσιευμένο',
      }))
      return data
    }

    if (url === 'offers/offer') {
      if (!response.ok) throw new Error('Αποτυχία ανάκτησης προσφορών.')

      sortByDatetime(results, 'expiration_date')

      const data = results.map((result: { expiration_date: string }) => ({
        ...result,
        expiration_date: dateFormat(result.expiration_date),
      }))
      return data
    }

    if (url === 'complaints/complaint') {
      if (!response.ok) throw new Error('Αποτυχία ανάκτησης παραπόνων.')

      return results
    }

    if (url === 'auth/customer/get-erps') {
      if (!response.ok) throw new Error('Αποτυχία ανάκτησης ERP χρηστών.')

      return results.data
    }

    if (url === 'auth/customer') {
      if (!response.ok) throw new Error('Αποτυχία ανάκτησης BFLOW χρηστών.')
      const data = results.data.map((user: BflowUserTypes) => ({
        ...user,
        userId: user.user.id,
        username: user.user.username,
        email: user.user.email,
      }))
      return data
    }

    if (url === 'privateMessages/pm') {
      if (!response.ok) throw new Error('Αποτυχία φόρτωσης μηνυμάτων')
      return results.data
    }

    if (url === 'products/product') {
      if (!response.ok) throw new Error('Αποτυχία φόρτωσης προϊόντων')

      return results.data
    }
  }

  const { data, error } = useSWR(token ? url : null, fetcher)

  const isLoading = !data && !error

  return {
    data,
    error,
    isLoading,
  }
}

export default useFetch
