import ReactDOM from 'react-dom'
import { StyledBackdrop } from '../UI/StyledBackdrop'

interface Props {
  children: React.ReactNode
  showModal: boolean
  onClickBackdrop?: () => void
}

const Modal = ({ children, showModal, onClickBackdrop }: Props) => {
  const overlay = document.getElementById('overlay') as HTMLElement

  return ReactDOM.createPortal(
    <>
      <StyledBackdrop showModal={showModal} onClick={onClickBackdrop} />
      {children}
    </>,
    overlay
  )
}

export default Modal
