import { useTable, usePagination, useGlobalFilter } from "react-table";
import { useLocation } from "react-router-dom";
import { IoChevronForwardOutline, IoChevronBackOutline } from "react-icons/io5";
import Search from "../Search/Search";
import * as Styled from "./TableItems.styles";
import { BFLOWUSERS, ERPUSERS } from "../../../constant/routes";
import {
  getNoResultMessage,
  getText,
  getTextPlural,
} from "../../../helpers/table-helpers";

interface Props {
  errorMessage?: Error;
  data: any;
  columns: any;
  pageSize?: number;
}
const TableItems = ({ data, columns, errorMessage, pageSize = 5 }: Props) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state,
    setGlobalFilter,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: pageSize },
    },
    useGlobalFilter,
    usePagination,
  );

  const { pageIndex, globalFilter } = state;

  const { pathname } = useLocation();
  const text = getText(pathname);
  const textPlural = getTextPlural(pathname);
  const noResultsMsg = getNoResultMessage(pathname);

  return (
    <>
      <Search
        filter={globalFilter}
        setFilter={setGlobalFilter}
        placeholder={
          pathname === ERPUSERS.main || pathname === BFLOWUSERS.main
            ? "Αναζήτηση (ERP ID, ΑΦΜ, Επωνυμία)"
            : "Αναζήτηση"
        }
      />
      <Styled.Table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Styled.Th {...column.getHeaderProps()}>
                  {column.render("Header")}
                </Styled.Th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <Styled.Td {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </Styled.Td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Styled.Table>
      {rows.length === 0 && (
        <Styled.NoResults>
          {!errorMessage ? noResultsMsg : errorMessage?.message}
        </Styled.NoResults>
      )}
      {rows.length !== 0 && (
        <Styled.PaginationWrapper>
          <Styled.PageInfo>
            {rows.length} {rows.length === 1 ? text : textPlural}
          </Styled.PageInfo>
          <Styled.ButtonWrapper>
            <Styled.PageInfo>
              Σελίδα {pageIndex + 1} of {pageOptions.length}
            </Styled.PageInfo>
            <Styled.PaginationButton
              onClick={previousPage}
              disabled={!canPreviousPage}
            >
              <IoChevronBackOutline />
            </Styled.PaginationButton>
            <Styled.PaginationButton onClick={nextPage} disabled={!canNextPage}>
              <IoChevronForwardOutline />
            </Styled.PaginationButton>
          </Styled.ButtonWrapper>
        </Styled.PaginationWrapper>
      )}
    </>
  );
};

export default TableItems;
