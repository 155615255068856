import { Redirect } from 'react-router-dom'
import { useContext } from 'react'
import { toast, Slide } from 'react-toastify'
import styled from 'styled-components'
import Header from '../../components/Header/Header'
import Navigation from '../../components/Navigation/Navigation'
import { authContext } from '../../context/auth-context'
import { LOGIN } from '../../constant/routes'
import 'react-toastify/dist/ReactToastify.css'
interface Props {
  children: React.ReactNode
}

toast.configure({
  position: 'top-center',
  autoClose: 3000,
  pauseOnFocusLoss: false,
  transition: Slide,
})

const Layout = ({ children }: Props) => {
  const { token } = useContext(authContext)

  if (!token) {
    return <Redirect to={LOGIN} />
  }
  return (
    <StyledMain>
      <Navigation />
      <StyledRightColWrapper>
        <Header />
        {children}
      </StyledRightColWrapper>
    </StyledMain>
  )
}

export default Layout

const StyledMain = styled.main`
  min-height: 100%;
  max-height: 100%;
  display: flex;
`

const StyledRightColWrapper = styled.section`
  min-height: 100%;
  width: 100%;
  overflow-y: auto;
  --ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`
