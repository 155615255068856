import moment from "moment";
import { useContext, useMemo } from "react";
import { MdDelete } from "react-icons/md";
import { RiEdit2Fill } from "react-icons/ri";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useSWRConfig } from "swr";
import { SALES } from "../../constant/routes";
import { authContext } from "../../context/auth-context";
import { deleteResourceById } from "../../helpers/deleteResourceById";
import { SaleTypes } from "../../interfaces/sales.interface";
import {
  StyledActionsWrapper,
  StyledImage,
  StyledLink,
  StyledSpan,
  StyledTableContainer,
} from "../UI/StyledTable";
import TableItems from "./TableItems/TableItems";
import { GrDocumentPdf } from "react-icons/gr";

interface Props {
  sales: SaleTypes[];
  salesError?: Error;
}

interface OriginalIdType {
  cell: { row: { original: { id: string } } };
}

const SalesTable = ({ sales, salesError }: Props) => {
  const { token, destroyToken } = useContext(authContext);
  const { mutate } = useSWRConfig();

  const columns = useMemo(
    () => [
      {
        Header: "Τίτλος",
        accessor: "title",
        Cell: ({ value }: { value: string }) => (
          <StyledP title={value}>{value}</StyledP>
        ),
      },
      {
        Header: "Περιγραφή",
        accessor: "description",
        Cell: ({ value }: { value: string }) => (
          <StyledP title={value}>{value || "-"}</StyledP>
        ),
      },
      {
        Header: "Ημ/νία Έναρξης",
        accessor: "start_date",
        Cell: ({ value }: { value: string }) => (
          <p>{moment(value).format("DD.MM.YYYY")}</p>
        ),
      },
      {
        Header: "Ημ/νία Λήξης",
        accessor: "expiration_date",
      },
      {
        Header: "Εικόνα",
        accessor: "image",
        Cell: ({ value }: { value: string }) => {
          return !value ? (
            <p>No image found</p>
          ) : (
            <StyledLink href={value} target="_blank" image={value}>
              <StyledImage src={value} alt="" />
            </StyledLink>
          );
        },
      },
      {
        Header: "Έκπτωση",
        accessor: "discount",
        Cell: ({ value }: { value: string }) => {
          return <StyledSpan>{value}%</StyledSpan>;
        },
      },
      {
        Header: "Ενέργειες",
        Cell: ({
          cell: {
            row: { original },
          },
        }: OriginalIdType) => {
          const urlDelete = `offers/offer/${original.id}/`;
          const urlEditLink = `${SALES.edit}/${original.id}`;
          const urlPdfLink = `${SALES.pdf}/${original.id}`;

          return (
            <StyledActionsWrapper>
              <Link to={urlPdfLink}>
                <GrDocumentPdf className="edit-icon" color="#000" />
              </Link>
              <Link to={urlEditLink}>
                <RiEdit2Fill className="edit-icon" color="#000" />
              </Link>
              <MdDelete
                onClick={() =>
                  deleteResourceById({
                    url: urlDelete,
                    token,
                    mutate,
                    destroyToken,
                  })
                }
                className="delete-icon"
              />
            </StyledActionsWrapper>
          );
        },
      },
    ],
    [token, mutate, destroyToken],
  );

  return (
    <StyledTableContainer>
      <TableItems data={sales} columns={columns} errorMessage={salesError} />
    </StyledTableContainer>
  );
};

export default SalesTable;

const StyledP = styled.p`
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
