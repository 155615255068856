import { Route } from "react-router-dom";
import { SALES } from "../../constant/routes";
import AddNewSale from "../../pages/Sales/AddNewSale";
import EditSale from "../../pages/Sales/EditSale";
import Sales from "../../pages/Sales/Sales";
import OfferPdf from "../../pages/Sales/components/OfferPdf/OfferPdf";

const SalesRoutes = () => {
  return (
    <>
      <Route path={SALES.main} exact>
        <Sales />
      </Route>
      <Route path={SALES.add} exact>
        <AddNewSale />
      </Route>
      <Route path={`${SALES.edit}/:id`} exact>
        <EditSale />
      </Route>
      <Route path={`${SALES.pdf}/:id`} exact>
        <OfferPdf />
      </Route>
    </>
  );
};

export default SalesRoutes;
