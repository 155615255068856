import { Route } from 'react-router-dom'
import { ERPUSERS } from '../../constant/routes'
import ErpUsers from '../../pages/ErpUsers/ErpUsers'
import EditErpUser from '../../pages/ErpUsers/EditErpUser'

const ErpUsersRoutes = () => {
  return (
    <>
      <Route path={ERPUSERS.main} exact>
        <ErpUsers />
      </Route>

      <Route path={`${ERPUSERS.edit}/:id`} exact>
        <EditErpUser />
      </Route>
    </>
  )
}

export default ErpUsersRoutes
