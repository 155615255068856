import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useSWRConfig } from 'swr'
import ConfirmActionModal from '../../components/Modal/ConfirmActionModal'
import BflowUsersTable from '../../components/Table/BflowUsersTable'
import Spinner from '../../components/UI/Spinners/Spinner'
import { StyledContainer } from '../../components/UI/StyledContainer'
import {
  StyledPageTitle,
  StyledPageTitleWrapper,
  StyledPageUserSubtitle,
} from '../../components/UI/StyledPageTitle'
import { BflowUserTypes } from '../../interfaces/bflowUser.interface'
import usersService from '../../services/usersService'

interface Props {
  bflowUsers: BflowUserTypes[]
  bflowUsersError: Error
  isLoading: boolean
}

const BflowUsers = ({ bflowUsers = [], bflowUsersError, isLoading }: Props) => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [resouseId, setResourceId] = useState<number | null>(null)
  const { mutate } = useSWRConfig()
  useEffect(() => {
    document.title = 'Aegean Fresko | BFLOW Users'
  }, [])

  const showModalHandler = (resourceId: number) => {
    setShowModal(true)
    setResourceId(resourceId)
  }

  const closeModalHandler = () => {
    setShowModal(false)
    setResourceId(null)
  }

  const onDeleteUserHandler = () => {
    usersService
      .deleteUser(resouseId!)
      .then(() => {
        mutate('auth/customer')
        toast.success('Ο χρήστης διαγράφηκε με επιτυχία!')
      })
      .catch(error => {
        toast.error(error.message)
      })
      .finally(() => {
        closeModalHandler()
      })
  }

  const onResetPasswordHandler = (username: string, email: string) => {
    usersService
      .resetPassword(username, email)
      .then(() => {
        toast.success('Το email στάλθηκε με επιτυχία!')
      })
      .catch(error => {
        toast.error(error.message)
      })
  }

  const onResetUsernameHandler = (email: string) => {
    usersService
      .resetUsername(email)
      .then(() => {
        toast.success('Το email στάλθηκε με επιτυχία!')
      })
      .catch(error => {
        toast.error(error.message)
      })
  }

  return (
    <>
      <ConfirmActionModal
        deleteResource={onDeleteUserHandler}
        showModal={showModal}
        closeModalHandler={closeModalHandler}
        description='Είστε σίγουροι ότι θέλετε να διαγράψετε τον χρήστη;'
      />
      <StyledContainer mainContent>
        <StyledPageTitleWrapper>
          <StyledPageTitle>Χρήστες BFLOW</StyledPageTitle>
        </StyledPageTitleWrapper>
        <StyledPageUserSubtitle>Εδώ εμφανίζονται όλοι οι χρήστες του BFLOW</StyledPageUserSubtitle>
        {isLoading ? (
          <Spinner />
        ) : (
          <BflowUsersTable
            bflowUsers={bflowUsers}
            bflowUsersError={bflowUsersError}
            showModalHandler={showModalHandler}
            onResetPasswordHandler={onResetPasswordHandler}
            onResetUsernameHandler={onResetUsernameHandler}
          />
        )}
      </StyledContainer>
    </>
  )
}

export default BflowUsers
