import styled from 'styled-components'
import Logo from '../UI/StyledLogo'
import NavLink from './NavLink/NavLink'
import { FaRegEnvelope, FaListUl } from 'react-icons/fa'
import { RiDashboardFill } from 'react-icons/ri'
import { FiUsers, FiInfo } from 'react-icons/fi'
import newsIcon from '../../assets/megaphone.svg'
import salesIcon from '../../assets/percentage.svg'
import {
  DASHBOARD,
  NEWS,
  SALES,
  ERPUSERS,
  BFLOWUSERS,
  COMPLAINTS,
  MESSAGES,
  PRODUCTS,
} from '../../constant/routes'

const Navigation = () => {
  return (
    <StyledBgColor>
      <StyledNavigationWrapper>
        <StyledLogoWrapper>
          <Logo />
        </StyledLogoWrapper>
        <nav>
          <ul>
            <NavLink path={DASHBOARD} isExact>
              <RiDashboardFill className='dashboard-icon' />
              <span>Πίνακας Ελέγχου</span>
            </NavLink>
            <NavLink path={PRODUCTS.main}>
              <FaListUl className='dashboard-icon' />
              <span>Προϊόντα</span>
            </NavLink>
            <NavLink path={MESSAGES.main}>
              <FaRegEnvelope className='dashboard-icon' />
              <span>Μηνύματα</span>
            </NavLink>
            <NavLink path={NEWS.main}>
              <img className='news-icon' src={newsIcon} alt='news icon' />
              <span>Νέα</span>
            </NavLink>
            <NavLink path={SALES.main}>
              <img className='sales-icon' src={salesIcon} alt='sales icon' />
              <span>Προσφορές</span>
            </NavLink>
            <NavLink path={ERPUSERS.main}>
              <FiUsers className='sales-icon' size={25} />
              <span>Χρήστες ERP</span>
            </NavLink>
            <NavLink path={BFLOWUSERS.main}>
              <FiUsers className='sales-icon' size={25} />
              <span>Χρήστες BFLOW</span>
            </NavLink>
            <NavLink path={COMPLAINTS.main}>
              <FiInfo className='sales-icon' size={25} />
              <span>Παράπονα</span>
            </NavLink>
          </ul>
        </nav>
      </StyledNavigationWrapper>
    </StyledBgColor>
  )
}

export default Navigation

const StyledBgColor = styled.div`
  background: linear-gradient(#00afef 50%, #f5f5f5 50%);
`

const StyledNavigationWrapper = styled.div`
  background-color: var(--white);
  min-width: 260px;
  min-height: 100%;
  padding: 32px 30px 30px 30px;
  border-radius: 0 20px 20px 0;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  @media (min-width: 1300px) {
    min-width: 330px;
    padding: 33px 38px 38px 38px;
  }
`

const StyledLogoWrapper = styled.div`
  border-bottom: 1px solid var(--border-light-gray);
  text-align: center;
  padding-bottom: 34px;
  margin-bottom: 20px;

  @media (min-width: 1300px) {
    margin-bottom: 35px;
  }
`
