import styled from 'styled-components'

interface StyledProps {
  showModal: boolean
}

export const StyledBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: ${({ showModal }: StyledProps) => (showModal ? 'rgba(0, 0, 0, 0.1)' : 'transparent')};
  transform: ${({ showModal }: StyledProps) => (showModal ? 'translateX(0)' : 'translateX(100%)')};
  transition: background-color 400ms;
`
