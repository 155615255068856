import React from "react";
import { View, StyleSheet, Text } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  contact: {
    borderTop: "1px solid #AACE46",
    paddingTop: 14,
    alignItems: "center",
  },
  text: {
    fontSize: 14,
    color: "#4A4A4A",
  },
});

const PdfContactInfo = () => {
  return (
    <View style={styles.contact}>
      <Text style={styles.text}>
        Πλάτανος, Χώρα Νάξου | 22850 23000 | info@aegeanfresko.gr |
        www.aegeanfresko.gr
      </Text>
    </View>
  );
};

export default PdfContactInfo;
