import styled, { css } from 'styled-components'

interface StyledProps {
  articles?: boolean
  image?: string
}

export const StyledTableContainer = styled.div`
  margin-top: 20px;
  width: 100%;

  @media (min-width: 1600px) {
    margin-top: 40px;
  }
`

export const StyledActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 18px;

  .edit-icon {
    color: var(--primary-color);
    transform: scale(1.2);
  }
  .delete-icon {
    color: var(--delete);
    cursor: pointer;
    transform: scale(1.2);
  }

  .svgAdd,
  .svgRemove {
    transition: all 200ms;
    cursor: pointer;
    padding-right: 0.3rem;
  }
  .svgAdd:hover {
    color: var(--primary-color);
  }
  .svgRemove:hover {
    color: var(--delete);
  }
`

export const StyledSpan = styled.span`
  text-transform: capitalize;
  ${({ articles }: StyledProps) => articles && 'color: var(--published)'};
`

export const StyledLink = styled.a`
  display: block;
  transition: color 200ms ease-out;
  border: 1px solid var(--border-light-gray);
  border-radius: 50%;
  width: 25px;
  height: 25px;
  position: relative;

  @media (min-width: 1350px) {
    &:hover::after {
      content: '';
      position: absolute;
      z-index: 10;
      border: 1px solid var(--border-light-gray);
      border-radius: 50%;
      width: 90px;
      height: 90px;
      right: -90px;
      bottom: 10px;

      ${({ image }: StyledProps) =>
        image &&
        css`
          background-image: url(${image});
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        `}
    }
  }

  @media (min-width: 1600px) {
    width: 35px;
    height: 35px;
  }
`

export const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
`
