import { useEffect } from 'react'
import styled from 'styled-components'
import DashboardNews from './components/DashboardNews/DashboardNews'
import DashboardSales from './components/DashboardSales/DashboardSales'
import { StyledContainer } from '../../components/UI/StyledContainer'
import { useOffersCtx } from '../../context/offers-context'
import { ArticleTypes } from '../../interfaces/news.interface'

interface DashboardProps {
  articles: ArticleTypes[]
  isArticlesLoading: boolean
  articlesError: Error
}

const Dashboard = ({ articles, isArticlesLoading, articlesError }: DashboardProps) => {
  const { offers } = useOffersCtx()
  useEffect(() => {
    document.title = 'Aegean Fresko | Dashboard'
  }, [])

  return (
    <StyledContainer dashboard>
      <StyledTopWrapper>
        <DashboardNews
          articles={articles}
          isArticlesLoading={isArticlesLoading}
          articlesError={articlesError}
        />
      </StyledTopWrapper>
      <DashboardSales sales={offers.data} isSalesLoading={offers.isLoading} salesError={offers.error} />
    </StyledContainer>
  )
}

export default Dashboard

const StyledTopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (min-width: 1350px) {
    flex-direction: row;
    justify-content: space-between;
    min-height: 50%;
  }
`
