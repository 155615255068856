import styled from 'styled-components'

export const StyledPageTitle = styled.h2`
  color: #000;
  font-size: 1.5rem;
  font-weight: var(--semi);
`

export const StyledPageTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`
export const StyledPageSubtitle = styled.h3`
  color: var(--primary-color);
  font-size: 1.375rem;
  margin-top: 10px;
  font-weight: var(--regular);
`

export const StyledPageUserSubtitle = styled.p`
  color: #c0c0c0;
  font-size: 0.875rem;
  margin-top: 5px;
`
