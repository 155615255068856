import { useContext, useMemo } from 'react'
import { MdDelete } from 'react-icons/md'
import { RiEdit2Fill } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { useSWRConfig } from 'swr'
import { NEWS } from '../../constant/routes'
import { authContext } from '../../context/auth-context'
import { deleteResourceById } from '../../helpers/deleteResourceById'
import { ArticleTypes } from '../../interfaces/news.interface'

import {
  StyledActionsWrapper,
  StyledImage,
  StyledLink,
  StyledSpan,
  StyledTableContainer,
} from '../UI/StyledTable'
import TableItems from './TableItems/TableItems'

interface Props {
  articles: ArticleTypes[]
  articlesError?: Error
}

interface OriginalIdType {
  cell: { row: { original: { id: string } } }
}

const ArticlesTable = ({ articles, articlesError }: Props) => {
  const { token, destroyToken } = useContext(authContext)
  const { mutate } = useSWRConfig()

  const columns = useMemo(
    () => [
      {
        Header: 'Τίτλος',
        accessor: 'title',
        Cell: ({ value }: { value: string }) => <StyledP title={value}>{value}</StyledP>,
      },
      {
        Header: 'Ημερομηνία',
        accessor: 'published',
      },
      {
        Header: 'Εικόνα',
        accessor: 'image',
        Cell: ({ value }: { value: string }) => {
          return !value ? (
            <p>No image found</p>
          ) : (
            <StyledLink href={value} target='_blank' image={value}>
              <StyledImage src={value} alt='' />
            </StyledLink>
          )
        },
      },
      {
        Header: 'Κατάσταση',
        accessor: 'status',
        Cell: ({ value }: { value: string }) => {
          return <StyledSpan articles={true}>{value}</StyledSpan>
        },
      },
      {
        Header: 'Ενέργειες',
        Cell: ({
          cell: {
            row: { original },
          },
        }: OriginalIdType) => {
          const urlDelete = `news/article/${original.id}/`
          const urlEditLink = `${NEWS.edit}/${original.id}`

          return (
            <StyledActionsWrapper>
              <Link to={urlEditLink}>
                <RiEdit2Fill className='edit-icon' color='#000' />
              </Link>
              <MdDelete
                onClick={() => deleteResourceById({ url: urlDelete, token, mutate, destroyToken })}
                className='delete-icon'
              />
            </StyledActionsWrapper>
          )
        },
      },
    ],
    [token, destroyToken, mutate]
  )

  return (
    <StyledTableContainer>
      <TableItems data={articles} columns={columns} errorMessage={articlesError} />
    </StyledTableContainer>
  )
}

export default ArticlesTable

const StyledP = styled.p`
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
