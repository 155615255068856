import styled from "styled-components";
import {
  StyledModal,
  StyledModalButtonWrapper,
  StyledModalButton,
} from "../UI/StyledModal";
import Modal from "./Modal";
import { TiWarningOutline } from "react-icons/ti";

interface Props {
  deleteResource: () => void;
  showModal: boolean;
  closeModalHandler: () => void;
  description: string;
}

const ConfirmActionModal = ({
  deleteResource,
  closeModalHandler,
  showModal,
  description,
}: Props) => {
  return (
    <Modal showModal={showModal}>
      <StyledModal showModal={showModal}>
        <StyledConfirmDelete>
          <TiWarningOutline className="warning" />
          <StyledDescription>{description}</StyledDescription>
          <StyledModalButtonWrapper>
            <StyledModalButton
              confirmDelete
              type="button"
              onClick={deleteResource}
            >
              Διαγραφη
            </StyledModalButton>
            <StyledModalButton type="button" onClick={closeModalHandler}>
              Ακυρωση
            </StyledModalButton>
          </StyledModalButtonWrapper>
        </StyledConfirmDelete>
      </StyledModal>
    </Modal>
  );
};

export default ConfirmActionModal;

const StyledConfirmDelete = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;

  .warning {
    font-size: 6.25rem;
    color: var(--delete);
  }
`;

const StyledDescription = styled.p`
  font-size: 1.125rem;
  font-weight: var(--medium);
  text-align: center;
  line-height: 25px;
  margin: auto 0;
`;
