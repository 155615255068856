import { NavLink as Link } from 'react-router-dom'
import styled from 'styled-components'

interface Props {
  children: React.ReactNode
  path: string
  isExact?: boolean
}

const NavLink = ({ children, path, isExact }: Props) => {
  return (
    <StyledLi>
      <Link to={path} exact={isExact} activeClassName='active'>
        {children}
      </Link>
    </StyledLi>
  )
}

export default NavLink

const StyledLi = styled.li`
  margin-bottom: 30px;

  a {
    color: #000;
    display: inline-block;
    font-size: 1.125rem;
    font-weight: var(--semi);
    padding: 12px 23px;
    display: flex;
    align-items: center;
  }

  .active {
    background-color: #dbf4fd;
    width: 100%;
    border-radius: 15px;
  }

  .dashboard-icon {
    display: block;
    font-size: 25px;
    margin-right: 21.5px;
  }

  .news-icon {
    margin-left: 2.5px;
    margin-right: 23.5px;
  }

  .sales-icon {
    margin-left: 2.5px;
    margin-right: 22.5px;
    width: 23px;
  }

  .product-icon {
    display: block;
    font-size: 25px;
    margin-left: 4px;
    margin-right: 20px;
  }
`
