import styled, { css } from 'styled-components'

interface StyledProps {
  showModal?: boolean
  confirmDelete?: boolean
}

const borderRadius = css`
  border-radius: 5px;
`

export const StyledModal = styled.div`
  position: fixed;
  background-color: var(--white);
  z-index: 20;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  ${borderRadius}
  z-index: 100;
  top: 50%;
  left: 50%;
  width: 620px;
  height: 370px;
  padding: 30px 50px 50px 50px;
  text-align: right;
  transform-origin: 0% 0%;
  opacity: ${({ showModal }: StyledProps) => (showModal ? '1' : '0')};
  transform: ${({ showModal }: StyledProps) =>
    showModal ? 'scale(1)  translate(-50%, -50%) ' : 'scale(0.5) translate(-50%, -50%)'};
  visibility: ${({ showModal }: StyledProps) => (showModal ? 'visible' : 'hidden')};
  transition: all 350ms;
`

export const StyledModalButtonWrapper = styled.div`
  margin-top: auto;
  display: flex;
  gap: 13px;
`

export const StyledModalButton = styled.button`
  display: inline-block;
  border: none;
  ${borderRadius}
  cursor: pointer;
  font-family: inherit;
  font-size: 1rem;
  outline: none;
  padding: 17px 30px;
  text-transform: uppercase;
  transition: all 200ms;
  width: 100%;
  &:hover,
  &:focus {
    opacity: 0.9;
  }

  ${({ confirmDelete }: StyledProps) => {
    if (confirmDelete) {
      return css`
        color: var(--white);
        background-color: var(--delete);
      `
    }
  }}
`

export const StyledUsersModal = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 600px;
  background-color: var(--white);
  z-index: 100;
  padding: 30px;
  transform: ${({ showModal }: StyledProps) => (showModal ? 'translateX(0)' : 'translateX(100%)')};
  transition: transform 350ms;
  border-radius: 5px 0 0 5px;
`

export const StyledProductsModal = styled.div`
  padding: 40px;
  width: 70%;
  height: max-content;
  background-color: var(--white);
  position: fixed;
  left: 50%;
  top: 50%;
  transition: transform 350ms;
  border-radius: 10px;
  transform: translate(-50%, -50%) ${({ showModal }: StyledProps) => (showModal ? 'scale(1)' : 'scale(0)')};
  transform-origin: center;
  z-index: 90;
`

export const StyledSelectedProductsModal = styled(StyledUsersModal)``
