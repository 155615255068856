import { useMemo } from 'react'
import { MdDelete } from 'react-icons/md'
import { HiOutlineMailOpen } from 'react-icons/hi'
import { BsImageFill } from 'react-icons/bs'
import { ComplaintTypes } from '../../interfaces/complaints.interface'
import { StyledActionsWrapper, StyledTableContainer } from '../UI/StyledTable'
import TableItems from './TableItems/TableItems'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { COMPLAINTS } from '../../constant/routes'
import moment from 'moment'

interface Props {
  complaints: ComplaintTypes[]
  complaintsError: Error
  showModalHandler: (resourceId: number) => void
}

interface OriginalIdType {
  cell: {
    row: {
      original: {
        id: number
        complaint_images: []
      }
    }
  }
}

const ComplaintsTable = ({ complaints = [], complaintsError, showModalHandler }: Props) => {
  const sortedComplaints = useMemo(
    () => complaints.sort((a, b) => b.date_created.localeCompare(a.date_created)),
    [complaints]
  )

  const columns = useMemo(
    () => [
      {
        Header: 'Πελάτης',
        accessor: 'customer_name',
        Cell: ({ value }: { value: string }) => <StyledP title={value}>{value || '-'}</StyledP>,
      },
      {
        Header: 'Τίτλος',
        accessor: 'title',
        Cell: ({ value }: { value: string }) => <StyledP title={value}>{value || '-'}</StyledP>,
      },
      {
        Header: 'Προϊόν',
        accessor: 'product_name',
        Cell: ({ value }: { value: string }) => <StyledP title={value}>{value || '-'}</StyledP>,
      },

      {
        Header: 'Περιγραφή',
        accessor: 'description',
        Cell: ({ value }: { value: string }) => <StyledP title={value}>{value || '-'}</StyledP>,
      },

      {
        Header: 'Εικόνες',
        Cell: ({
          cell: {
            row: { original },
          },
        }: OriginalIdType) => (
          <StyledDiv>
            <p>{original.complaint_images.length}</p>
            <BsImageFill color='#C1C1C1' />
          </StyledDiv>
        ),
      },

      {
        Header: 'Ημερομηνία',
        accessor: 'date_created',
        Cell: ({ value }: { value: string }) => <p>{moment(value).format('DD/MM/YYYY')}</p>,
      },
      {
        Header: 'Ενέργειες',
        Cell: ({
          cell: {
            row: { original },
          },
        }: OriginalIdType) => {
          return (
            <StyledActionsWrapper>
              <Link to={`${COMPLAINTS.view}/${original.id}`}>
                <HiOutlineMailOpen size={18} color='#00506D' />
              </Link>
              <MdDelete
                size={18}
                cursor='pointer'
                color='#ff3434'
                onClick={() => showModalHandler(original.id)}
              />
            </StyledActionsWrapper>
          )
        },
      },
    ],
    [showModalHandler]
  )

  return (
    <StyledTableContainer>
      <TableItems data={sortedComplaints} columns={columns} errorMessage={complaintsError} />
    </StyledTableContainer>
  )
}

export default ComplaintsTable

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

const StyledP = styled.p`
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
