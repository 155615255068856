import { useState, useContext } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { StyledContainer } from '../../components/UI/StyledContainer'
import { StyledPageTitle } from '../../components/UI/StyledPageTitle'
import { authContext } from '../../context/auth-context'
import { PRODUCTS } from '../../constant/routes'
import ProductForm from './components/ProductForm'
import { ProductTypes } from '../../interfaces/products.interface'
import { useSWRConfig } from 'swr'
import productsService from '../../services/productsService'

interface Props {
  products: ProductTypes[]
}

const EditProduct = ({ products = [] }: Props) => {
  const [isFormLoading, setIsFormLoading] = useState<boolean>(false)
  const { token } = useContext(authContext)
  const history = useHistory()
  const { mutate } = useSWRConfig()
  const params: { id: string } = useParams()

  const product = products.find(item => item.productid === +params.id)

  const onSubmit = async (values: { image: string }) => {
    setIsFormLoading(true)

    if (product?.product_photo === values.image) {
      toast.warn('Δεν έγινε καμία αλλαγή')
      setIsFormLoading(false)
      return
    }

    productsService
      .updateProductImage({ image: values.image, id: product?.productid!, token: token! })
      .then(() => {
        mutate('products/product')
        setIsFormLoading(false)
        toast.success('Η εικόνα επεξεργάστηκε επιτυχώς')
        history.push(PRODUCTS.main)
      })
      .catch(() => setIsFormLoading(false))
  }

  return (
    <StyledContainer formContent>
      <StyledPageTitle>Επεξεργασία προϊόντος</StyledPageTitle>
      <ProductForm product={product} onSubmit={onSubmit} isFormLoading={isFormLoading} />
    </StyledContainer>
  )
}

export default EditProduct
