import { useHistory } from 'react-router-dom'
import { StyledButton } from '../../components/UI/StyledButton'
import { StyledContainer } from '../../components/UI/StyledContainer'
import { StyledPageTitle, StyledPageTitleWrapper } from '../../components/UI/StyledPageTitle'
import { useEffect } from 'react'
import { IoMdAdd } from 'react-icons/io'
import Spinner from '../../components/UI/Spinners/Spinner'
import { SALES } from '../../constant/routes'
import SalesTable from '../../components/Table/SalesTable'
import { useOffersCtx } from '../../context/offers-context'

const Sales = () => {
  const history = useHistory()
  const { offers } = useOffersCtx()

  useEffect(() => {
    document.title = 'Aegean Fresko | Προσφορές'
  }, [])

  return (
    <StyledContainer mainContent>
      <StyledPageTitleWrapper>
        <StyledPageTitle>Προσφορές</StyledPageTitle>
        <StyledButton onClick={() => history.push(SALES.add)} publish>
          <IoMdAdd className='add-icon' />
          Προσθήκη Νέου
        </StyledButton>
      </StyledPageTitleWrapper>
      {offers.isLoading ? <Spinner /> : <SalesTable sales={offers.data} salesError={offers.error} />}
    </StyledContainer>
  )
}

export default Sales
