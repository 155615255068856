import { ErpUserFormTypes } from '../interfaces/erpUser.interface'

class UsersService {
  async registerUser(values: ErpUserFormTypes) {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/auth/customer/register-via-erp/`, {
        method: 'POST',
        body: JSON.stringify({
          erp_id: +values.erpId,
          username: values.username,
          password: values.password,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
      const data = await response.json()
      if (response.status === 201) {
        return data
      }
    } catch (error) {
      throw new Error('Αποτυχία δημιουργίας χρήστη')
    }
  }

  async deleteUser(id: number) {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/auth/customer/${id}/delete/`)
      if (!response.ok) {
        throw Error
      }
    } catch (error) {
      throw new Error('Αποτυχία διαγραφής χρήστη')
    }
  }

  async resetPassword(username: string, email: string) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_HOST}/api/auth/customer/forgot-password-request/`,
        {
          method: 'POST',
          body: JSON.stringify({
            username,
            email,
          }),
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      if (!response.ok) {
        throw Error
      }
    } catch (error) {
      throw new Error('Αποτυχία αποστολής email για επαναφορά κωδικού')
    }
  }

  async resetUsername(email: string) {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/auth/customer/forgot-username/`, {
        method: 'POST',
        body: JSON.stringify({
          email,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
      if (!response.ok) {
        throw Error
      }
    } catch (error) {
      throw new Error('Αποτυχία αποστολής email για επαναφορά ονόματος χρήστη')
    }
  }
}

export default new UsersService()
