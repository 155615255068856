import { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useSWRConfig } from 'swr'
import { IoMdAdd } from 'react-icons/io'
import { useHistory } from 'react-router-dom'
import MessagesTable from '../../components/Table/MessagesTable'
import Spinner from '../../components/UI/Spinners/Spinner'
import { StyledButton } from '../../components/UI/StyledButton'
import { StyledContainer } from '../../components/UI/StyledContainer'
import { StyledPageTitle, StyledPageTitleWrapper } from '../../components/UI/StyledPageTitle'
import { MESSAGES } from '../../constant/routes'
import useFetch from '../../hooks/useFetch'
import { MessagesDataTypes } from '../../interfaces/messages.interface'
import messagesService from '../../services/messagesService'
import { authContext } from '../../context/auth-context'
import ConfirmActionModal from '../../components/Modal/ConfirmActionModal'

const Messages = () => {
  const history = useHistory()
  const { token } = useContext(authContext)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [resouseId, setResourceId] = useState<number | null>(null)
  const { mutate } = useSWRConfig()

  const {
    data: messages,
    isLoading,
    error: messagesError,
  }: MessagesDataTypes = useFetch('privateMessages/pm')

  useEffect(() => {
    document.title = 'Aegean Fresko | Μηνύματα'
  }, [])

  const showModalHandler = (resourceId: number) => {
    setShowModal(true)
    setResourceId(resourceId)
  }

  const closeModalHandler = () => {
    setShowModal(false)
    setResourceId(null)
  }

  const onDeleteUserHandler = () => {
    messagesService
      .deleteMessage(resouseId!, token!)
      .then(() => {
        mutate('privateMessages/pm')
        toast.success('Το μήνυμα διαγράφηκε με επιτυχία!')
      })
      .catch(error => {
        toast.error(error.message)
      })
      .finally(() => {
        closeModalHandler()
      })
  }

  return (
    <>
      <ConfirmActionModal
        deleteResource={onDeleteUserHandler}
        showModal={showModal}
        closeModalHandler={closeModalHandler}
        description='Είστε σίγουροι ότι θέλετε να διαγράψετε το μήνυμα'
      />
      <StyledContainer mainContent>
        <StyledPageTitleWrapper>
          <StyledPageTitle>Μηνύματα</StyledPageTitle>
          <StyledButton onClick={() => history.push(MESSAGES.add)} publish>
            <IoMdAdd className='add-icon' />
            Προσθήκη Νέου
          </StyledButton>
        </StyledPageTitleWrapper>
        {isLoading ? (
          <Spinner />
        ) : (
          <MessagesTable
            showModalHandler={showModalHandler}
            messages={messages}
            messagesError={messagesError}
          />
        )}
      </StyledContainer>
    </>
  )
}

export default Messages
