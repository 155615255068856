import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'
import DashboardSalesItem from './DashboardSalesItem/DashboardSalesItem'
import { StyledButton } from '../../../../components/UI/StyledButton'
import { StyledPageTitle } from '../../../../components/UI/StyledPageTitle'
import { SALES } from '../../../../constant/routes'
import { SaleTypes } from '../../../../interfaces/sales.interface'
import { IoMdAdd } from 'react-icons/io'
import { IoChevronBack, IoChevronForward } from 'react-icons/io5'
import Spinner from '../../../../components/UI/Spinners/Spinner'
import { StyledUIMsg } from '../../../../components/UI/StyledUIMsg'

interface Props {
  sales: SaleTypes[]
  isSalesLoading: boolean
  salesError: Error
}

interface StyledProps {
  currentIndex: number
  salesLength: number
}

const DashboardSales = ({ sales = [], isSalesLoading, salesError }: Props) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const [length, setLength] = useState<number>(sales.length)
  const [touchPosition, setTouchPosition] = useState<number | null>(null)

  const history = useHistory()

  useEffect(() => setLength(sales.length), [sales])

  const displayImages = sales.map(sale => (
    <DashboardSalesItem
      key={sale.id}
      title={sale.title}
      image={sale.image}
      discount={sale.discount}
      id={sale.id}
    />
  ))

  const handleTouchStart = (e: React.TouchEvent) => {
    const touchDown = e.touches[0].clientX
    setTouchPosition(touchDown)
  }

  const handleTouchMove = (e: React.TouchEvent) => {
    const touchDown = touchPosition

    if (touchDown === null) {
      return
    }

    const currentTouch = e.touches[0].clientX
    const diff = touchDown - currentTouch

    if (diff < -5) {
      prevImage()
    }

    if (diff > 5) {
      nextImage()
    }

    setTouchPosition(null)
  }

  const prevImage = () => {
    if (currentIndex > 0) {
      setCurrentIndex(prevState => prevState - 1)
    }
  }

  const nextImage = () => {
    if (currentIndex < length - 2.55) {
      setCurrentIndex(prevState => prevState + 1)
    }
  }

  return (
    <DashboardSalesContainer onTouchStart={handleTouchStart} onTouchMove={handleTouchMove}>
      {currentIndex > 0 && sales.length !== 0 && (
        <IoChevronBack onClick={prevImage} className='arrow-previous' />
      )}
      {currentIndex < length - 2.55 && sales.length !== 0 && (
        <IoChevronForward onClick={nextImage} className='arrow-next' />
      )}
      <StyledContentWrapper>
        <StyledTitleWrapper>
          <StyledPageTitle>Προσφορές</StyledPageTitle>
          {process.env.NODE_ENV === 'development' && (
            <StyledButton onClick={() => history.push(SALES.add)} publish>
              <IoMdAdd className='add-icon' />
              Προσθήκη Νέου
            </StyledButton>
          )}
        </StyledTitleWrapper>
        <StyledImageCarousel currentIndex={currentIndex} salesLength={sales.length}>
          {isSalesLoading && <Spinner dashboard='center' />}
          {!isSalesLoading && !salesError && displayImages}
          {!isSalesLoading && !salesError && sales.length === 0 && (
            <StyledUIMsg>Δεν έχετε προσθέσει ακόμη προσφορές στη λίστα προσφορών σας</StyledUIMsg>
          )}
          {!isSalesLoading && salesError && <StyledUIMsg>{salesError.message}</StyledUIMsg>}
        </StyledImageCarousel>
      </StyledContentWrapper>
    </DashboardSalesContainer>
  )
}

export default DashboardSales

const DashboardSalesContainer = styled.div`
  display: flex;
  position: relative;
  height: 350px;
  max-width: 100%;
  padding: 30px;
  background-color: var(--white);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  border-radius: 14px;
  overflow: hidden;

  .arrow-previous {
    color: var(--white);
    position: absolute;
    top: 59%;
    left: 30px;
    z-index: 10;
    transform: scale(2.2);
    cursor: pointer;
  }

  .arrow-next {
    color: var(--white);
    position: absolute;
    top: 59%;
    right: 30px;
    z-index: 10;
    transform: scale(2.2);
    cursor: pointer;
  }

  @media (hover: none) and (pointer: coarse) {
    .arrow-previous,
    .arrow-next {
      display: none;
    }
  }

  @media (min-width: 1350px) {
    height: 100%;
  }
`

const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 25px;
  width: 100%;
`

const StyledImageCarousel = styled.div`
  position: absolute;
  display: flex;
  top: 50%;
  transform: ${({ currentIndex }: StyledProps) =>
    `translateX(-${currentIndex * (100 / 2.5)}%) translateY(-30%)`};
  transition: transform 500ms;
  min-height: 150px;
  gap: 30px;

  ${({ salesLength }: StyledProps) =>
    salesLength === 0 &&
    css`
      position: unset !important;
      transform: unset !important;
      display: block !important;
    `}

  @media (min-width: 1300px) {
    min-height: 180px;
    transform: ${({ currentIndex }: StyledProps) =>
      `translateX(-${currentIndex * (100 / 3.5)}%)  translateY(-30%)`};
  }

  @media (min-width: 1600px) {
    transform: ${({ currentIndex }: StyledProps) =>
      `translateX(-${currentIndex * (100 / 4.55)}%)  translateY(-30%)`};
  }
`
